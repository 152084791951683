import { z } from 'zod';
import { INTERNAL_EMAIL_DOMAINS } from 'src/utils/isInternalEmail';

export const MAX_EMAILS_PER_INVITATION = 20;

export const RESTRICTED_EMAIL_ERROR_MESSAGE = `This project only allows emails with the following domains: ${INTERNAL_EMAIL_DOMAINS.join(
  ', '
)}`;

export const isEmailValueValid = (email: string): boolean => {
  // for some MGO email contains ', we need exclude the special character '
  const looseEmail = email.replace(/.*?@/, (match) => match.replace(/'/g, ''));
  return z.string().email().safeParse(looseEmail).success;
};
