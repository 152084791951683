export const EXTERNAL_LINK_TITLE_TEMPLATE = `Open {linkName} in a new window` as const;

export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';

// as the `useFetchMe` will be refetched every 5 minutes, and it is acting as a session refresh mechanism at the moment.
// keep this 10 minute for now, so the token can be refreshed before it's considered expired.
// will be aligned with the token expiry threshold across Moata Apps once we have a scheduled session refresh mechanism.
export const TOKEN_EXPIRY_THRESHOLD_IN_MS = 1000 * 60 * 10;

/**
 * For cases like delayed loading spinner.
 */
export const MINIMUM_NOTICEABLE_INTERACTION_DELAY_IN_MS = 300;
export const MINIMUM_LOADING_ANIMATION_DURATION_IN_MS = 600;
/**
 * For cases like debounced typing.
 */
export const MAXIMUM_REPEATED_INTERACTION_DURATION_IN_MS = 300;
