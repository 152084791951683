/**
 * Throws an error if what you're passing is undefined
 * If ok, returns what you gave it
 */
const assertIsNotUndefined = <T>(value: T | undefined): T => {
  if (value === undefined) {
    throw new Error('assertValue found an undefined value');
  }

  return value;
};

export default assertIsNotUndefined;
