import { FC, SyntheticEvent } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FetchErrorResult } from 'src/ui/Result';
import { useCurrentProductId } from 'src/features/admin/hooks/useCurrentProductId';
import { AdminItemType, AdminItemConfigurationPageTab } from 'src/features/admin/types';
import Groups from 'src/features/admin/components/Groups';
import { RouteBuilder } from 'src/router';
import Actions from 'src/features/admin/components/Actions';
import { AdminItemConfigurationPageTabs } from 'src/features/admin/constants';

export const ProductConfigurationPage: FC<{ selectedTab: AdminItemConfigurationPageTab }> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const productId = useCurrentProductId();

  const onTabSelect = (event: SyntheticEvent, newTab: AdminItemConfigurationPageTab): void =>
    navigate(`/${RouteBuilder.adminView(AdminItemType.Product, Number(productId), newTab)}`);

  if (!productId) return <FetchErrorResult />;

  return (
    <Box gap={4} display="flex" flexDirection="column">
      <Tabs value={selectedTab} onChange={onTabSelect} variant="fullWidth">
        <Tab data-testid="groups-tab" label="Groups" value={AdminItemConfigurationPageTabs.Groups} />
        <Tab data-testid="actions-tab" label="Actions" value={AdminItemConfigurationPageTabs.Actions} />
      </Tabs>

      <Box hidden={selectedTab !== AdminItemConfigurationPageTabs.Groups}>
        <Groups itemType={AdminItemType.Product} itemId={productId} />
      </Box>
      <Box hidden={selectedTab !== AdminItemConfigurationPageTabs.Actions}>
        <Actions productId={Number(productId)} itemType={AdminItemType.Product} />
      </Box>
    </Box>
  );
};
