import { ProjectMetadata } from '@h2know-how/identity-sdk';
import { FC } from 'react';
import uniqueid from 'lodash.uniqueid';
import { useInfiniteScroll } from 'ahooks';
import { Result, NoSearchResult } from 'src/ui/Result';
import ControlledGrid from 'src/ui/ControlledGrid';
import LoaderTile from 'src/ui/LoaderTile';
import ProjectTile from 'src/features/lobby/components/ProjectTile';
import useGetGridColumns from 'src/hooks/useGetGridColumns';

const PAGE_SIZE = 24; // 24 can show full rows for all `columns` sizes

interface PageData {
  list: ProjectMetadata[];
  nextIndex?: number;
}

interface LazyRenderProjectsGridProps {
  projects?: ProjectMetadata[];
  isLoading?: boolean;
  isEmptyDataResult?: boolean;
  isNoSearchResult?: boolean;
  userId?: string;
  isSuperAdmin?: boolean;
}

const LazyRenderProjectsGrid: FC<LazyRenderProjectsGridProps> = ({
  projects,
  isLoading = false,
  isEmptyDataResult = false,
  isNoSearchResult = false,
  userId,
  isSuperAdmin = false,
}) => {
  const columns = useGetGridColumns();

  const getLoadMoreList = async (startIndex = 0): Promise<PageData> => {
    const endIndex = startIndex + PAGE_SIZE;
    const list = projects?.slice(startIndex, endIndex) ?? [];
    return { list, nextIndex: endIndex };
  };

  const { data: pageData, loadingMore: isLoadingMorePage } = useInfiniteScroll<PageData>(
    (d) => getLoadMoreList(d?.nextIndex),
    {
      target: document,
      threshold: 200,
      isNoMore: (d) => d?.nextIndex === undefined || d.nextIndex >= (projects?.length ?? 0),
      reloadDeps: [projects],
    }
  );

  if (isEmptyDataResult) {
    return <Result severity="info" title="No projects found" />;
  }

  if (isNoSearchResult) {
    return <NoSearchResult />;
  }

  return (
    <ControlledGrid columns={columns}>
      {pageData?.list?.map((project) => (
        <ProjectTile key={project.projectId} project={project} userId={userId} isSuperAdmin={isSuperAdmin} />
      ))}
      {(isLoading || isLoadingMorePage) &&
        [...Array(columns)].map(() => (
          <LoaderTile mediaHeight={184} mediaAlt="This is a project tile loading" key={uniqueid('key_')} />
        ))}
    </ControlledGrid>
  );
};

export default LazyRenderProjectsGrid;
