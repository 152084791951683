import { FC } from 'react';
import { IconButton, Dialog, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';

import { SmDetailPanelProps } from '../types';

const SmDetailPanel: FC<SmDetailPanelProps> = ({
  isOpen,
  handlePanelClose,
  getDetailPanelTitle,
  getDetailPanelContent,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handlePanelClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="dialog-title"
      >
        {getDetailPanelTitle()}
        <IconButton
          edge="end"
          size="small"
          aria-label="info"
          onClick={handlePanelClose}
          data-gtm-detail-close="sm"
          data-testid="close-dialog-icon"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      {getDetailPanelContent()}
    </Dialog>
  );
};

export default SmDetailPanel;
