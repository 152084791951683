import { FC, ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAppDispatch } from 'src/store/useAppDispatch';

import getReactQueryClient from 'src/utils/getReactQueryClient';

interface Props {
  children: ReactElement;
}

/**
 * Wraps QueryClientProvider from react-query and provides it with the appropriate client
 */
const AppQueryClientProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();

  return (
    <QueryClientProvider client={getReactQueryClient(dispatch)}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppQueryClientProvider;
