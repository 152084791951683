import { ProjectDto } from '@h2know-how/identity-sdk';
import { createContext, FC, useMemo, useState, useCallback, PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import ProjectEditModal from '../ProjectEditModal';

export interface ProjectEditContextValue {
  openProjectEditModal: (initialValues?: { productId?: number; project?: ProjectDto }) => void;
}

const ProjectEditContext = createContext<ProjectEditContextValue>({
  openProjectEditModal: () => {},
});

const ProjectEditContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, toggleModal] = useToggle(false);
  const [initialProductId, setInitialProductId] = useState<number>();
  const [initialProject, setInitialProject] = useState<ProjectDto>();

  const value = useMemo(
    () => ({
      openProjectEditModal: (initialValues?: { productId?: number; project?: ProjectDto }) => {
        toggleModal(true);
        setInitialProductId(initialValues?.productId);
        setInitialProject(initialValues?.project);
      },
    }),
    [toggleModal]
  );

  const closeProjectEdit = useCallback(() => {
    toggleModal(false);
    setInitialProductId(undefined);
    setInitialProject(undefined);
  }, [toggleModal]);

  return (
    <ProjectEditContext.Provider value={value}>
      {children}
      {isModalOpen && (
        <ProjectEditModal
          open={isModalOpen}
          onClose={closeProjectEdit}
          initialProject={initialProject}
          initialProductId={initialProductId}
        />
      )}
    </ProjectEditContext.Provider>
  );
};

export { ProjectEditContext, ProjectEditContextProvider };
