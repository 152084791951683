import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type MediaQuery = 'up' | 'only';

interface ScreenSizeMap {
  isXL: boolean;
  isLg: boolean;
  isMd: boolean;
  isSm: boolean;
  isXs: boolean;
}

const useGetScreenSize = (mediaQuery: MediaQuery = 'only'): ScreenSizeMap => {
  const theme = useTheme();

  const isXL = useMediaQuery(theme.breakpoints[mediaQuery]('xl'));
  const isLg = useMediaQuery(theme.breakpoints[mediaQuery]('lg'));
  const isMd = useMediaQuery(theme.breakpoints[mediaQuery]('md'));
  const isSm = useMediaQuery(theme.breakpoints[mediaQuery]('sm'));
  const isXs = useMediaQuery(theme.breakpoints[mediaQuery]('xs'));

  return { isXL, isLg, isMd, isSm, isXs };
};

export default useGetScreenSize;
