import { FC } from 'react';
import { Menu, MenuItem, Link, ListItemText, LinkProps, Typography, MenuItemProps } from '@mui/material';
import { HELPER_LINKS } from 'src/features/lobby/components/Header/HelperMenu/helperLinks';
import { renderPlaceholderTemplate } from 'src/utils/renderPlaceholderTemplate';
import { EXTERNAL_LINK_TITLE_TEMPLATE } from 'src/utils/constants';
import { HELP_BAR_ITEM_NAME } from '../constants';

const getItemTitle = ({ label, link }: { label: string; link?: string }): string | undefined =>
  link ? renderPlaceholderTemplate(EXTERNAL_LINK_TITLE_TEMPLATE, { linkName: label }) : undefined;

const getLinkProps = ({ url }: { url?: string } = {}): Partial<MenuItemProps & LinkProps> | undefined =>
  url
    ? {
        component: Link,
        target: '_blank',
        rel: 'noopener noreferrer',
        href: url,
        color: 'inherit',
        underline: 'none',
      }
    : undefined;

const StdHelperMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  onItemClick: ({ name }: { name: string }) => void;
  onShowChameleonHelpBarClick: ({ name }: { name: string }) => void;
}> = ({ anchorElement, onItemClick, onShowChameleonHelpBarClick }) => {
  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onItemClick}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      MenuListProps={{ component: 'div' }}
      slotProps={{
        paper: { sx: { minWidth: '220px' } },
      }}
    >
      {HELPER_LINKS.map((item) => (
        <MenuItem
          component="div"
          key={`${item.label}-${item.link}`}
          data-testid={item.id}
          onClick={() => {
            onItemClick({ name: item.label });
          }}
          title={getItemTitle(item)}
          {...getLinkProps({ url: item.link })}
        >
          {item.label}
        </MenuItem>
      ))}
      <MenuItem
        component="div"
        onClick={() => {
          onShowChameleonHelpBarClick({ name: HELP_BAR_ITEM_NAME });
        }}
      >
        <ListItemText primary={HELP_BAR_ITEM_NAME} />
        <Typography variant="caption" color="text.disabled">
          Ctrl + K
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default StdHelperMenu;
