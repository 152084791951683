import { Dispatch } from 'redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { MutationCache, QueryCache, QueryClient } from 'react-query';

import serializeApiError from 'src/store/utils/serializeApiError';
import parseAPIError from 'src/utils/parseAPIError';

// exported so it can be tested separately
export const onError = (dispatch: Dispatch<UnknownAction>) => (error: unknown) =>
  parseAPIError(serializeApiError(error), dispatch);

/**
 * Sets up query defaults and error middleware
 * Returns a query client for our react-query provider
 * Read more on react-query's defaults here https://react-query.tanstack.com/guides/important-defaults
 */
const getReactQueryClient = (dispatch: Dispatch<UnknownAction>): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        // Turn this on only for production so react-query doesn't refetch every time we go into our devtools
        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      },
    },
    queryCache: new QueryCache({
      onError: onError(dispatch),
    }),
    mutationCache: new MutationCache({
      onError: onError(dispatch),
    }),
  });

export default getReactQueryClient;
