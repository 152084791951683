import { createAsyncThunk } from '@reduxjs/toolkit';

import { CLIENT_ID } from 'src/configs';
import { AppFullRoutePath, AppPage, LoginPageSearchParam } from 'src/router';
import { getAppOrigin } from 'src/utils/url';
import serializeApiError from '../../utils/serializeApiError';
import { LoginResponsePayload } from '../types';
import getPolicyMetadata from '../utils/getPolicyMetadata';
import { encodeB2cStatePayload } from '../utils/b2cStatePayload';
import { AUTH_SLICE_NAME } from '../sliceName';

const getEncodedState = (urlSearchParams: URLSearchParams): string => {
  const callbackUriParam = urlSearchParams.get(LoginPageSearchParam.CallbackUri) ?? undefined;
  const stateParam = urlSearchParams.get(LoginPageSearchParam.State) ?? undefined;
  return (
    encodeB2cStatePayload(
      callbackUriParam || stateParam ? { callbackUri: callbackUriParam, state: stateParam } : undefined
    ) ?? ''
  );
};

const login = createAsyncThunk<LoginResponsePayload, { email: string; urlSearchParams: URLSearchParams }>(
  `${AUTH_SLICE_NAME}/login`,
  async ({ email, urlSearchParams }, { signal }) => {
    const {
      policy,
      authorization_endpoint: authorizationEndpoint,
      end_session_endpoint: endSessionEndpoint,
    } = await getPolicyMetadata(email, signal);

    // ref: https://learn.microsoft.com/en-us/azure/active-directory-b2c/authorization-code-flow#1-get-an-authorization-code
    const defaultParams = {
      client_id: CLIENT_ID,
      // NOTE: I added these as defaults, they seem important?
      response_type: 'code',
      redirect_uri: `${getAppOrigin()}${AppFullRoutePath[AppPage.Callback]}`,
      response_mode: 'query',
      scope: `${CLIENT_ID} offline_access`,
      login_hint: email,
    };

    const hasThirdPartyRedirectUri = urlSearchParams.has(LoginPageSearchParam.RedirectUri);

    const loginSearchParams = new URLSearchParams(
      hasThirdPartyRedirectUri
        ? // for the existing scenario, check out the comment at {@link LoginPageSearchParam.RedirectUri}.
          { ...defaultParams, ...Object.fromEntries(urlSearchParams.entries()) }
        : // for the new scenario, check out the comment at {@link CallbackPageSearchParam.State}.
          { ...defaultParams, state: getEncodedState(urlSearchParams) }
    );

    const projectLoginSearchParams = new URLSearchParams({
      client_id: defaultParams.client_id,
      redirect_uri: '',
    });

    const logoutSearchParams = new URLSearchParams({
      post_logout_redirect_uri: `${getAppOrigin()}${AppFullRoutePath[AppPage.LogoutConfirmation]}`,
    });

    return {
      policy,
      loginUrl: `${authorizationEndpoint}&${loginSearchParams.toString()}`,
      projectLoginUrl: `${authorizationEndpoint}&${projectLoginSearchParams.toString()}`,
      projectLogoutUrl: `${endSessionEndpoint}&${logoutSearchParams.toString()}`,
    };
  },
  { serializeError: serializeApiError }
);

export default login;
