// implementation pattern learnt from https://github.com/chamaeleonidae/chmln/blob/47579523236b62ef4362e0d7deb6541a70dd5ef4/index.js#L19
export const showChameleon: NonNullable<typeof window.chmln>['show'] = (tourId, options) => {
  if (!window.chmln?.show) {
    // eslint-disable-next-line no-console
    console.log(`Failed to show the tour [${tourId}] because the chameleon.io script was not initialized yet.`);
    return;
  }

  window.chmln.show(tourId, options);
};
