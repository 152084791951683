import { Breadcrumbs, capitalize, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Outlet, Link as ReactRouterLink, useMatch } from 'react-router-dom';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { AppFullRoutePath, AppPage } from 'src/router';
import { AdminItemType } from 'src/features/admin/types';

interface GroupAdminPageLayoutProps {
  linkUrl: string;
  itemName?: string;
  itemType: AdminItemType;
  configPage: typeof AppPage.ProjectConfiguration | AppPage.ProductConfiguration;
  groupConfigPage: typeof AppPage.ProjectGroupConfiguration | AppPage.ProductGroupConfiguration;
  userConfigPage?: typeof AppPage.ProjectUserConfiguration;
}
export const GenericGroupAdminPageLayout: FC<GroupAdminPageLayoutProps> = ({
  linkUrl,
  itemName,
  itemType,
  configPage,
  groupConfigPage,
  userConfigPage = AppPage.ProjectUserConfiguration,
}) => {
  const isConfigPage = useMatch(AppFullRoutePath[configPage]);
  const isGroupConfigPage = useMatch(AppFullRoutePath[groupConfigPage]);
  const isUserConfigPage = useMatch(AppFullRoutePath[userConfigPage]);

  useDocumentTitle([itemName ? `${itemType}: ${itemName}` : '', 'Admin']);

  if (!itemName) return null;

  return (
    <Stack>
      <Breadcrumbs sx={{ p: 2 }}>
        <Link to="/" component={ReactRouterLink}>
          Home
        </Link>
        {isConfigPage ? (
          <Typography component="h1">
            {capitalize(itemType)}: {itemName}
          </Typography>
        ) : (
          <Link to={`/${linkUrl}`} component={ReactRouterLink}>
            {capitalize(itemType)}: {itemName}
          </Link>
        )}

        {isGroupConfigPage ? <Typography component="h1">{capitalize(itemType)} group configuration</Typography> : null}

        {isUserConfigPage ? <Typography component="h1">{capitalize(itemType)} user configuration</Typography> : null}
      </Breadcrumbs>
      <Outlet />
    </Stack>
  );
};
