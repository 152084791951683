import { FC } from 'react';
import { Button } from '@mui/material';

import { LinkType } from '../types';

interface Props {
  label: string;
  type: LinkType;
  link: string;
}

const ActionButton: FC<Props> = ({ label, link }: Props) => {
  if (!label || !link) return null;

  return (
    <Button
      variant="text"
      size="small"
      href={link}
      target="_blank"
      data-testid="productTile-actionButton"
      data-gtm-product-link={label}
    >
      {label}
    </Button>
  );
};

export default ActionButton;
