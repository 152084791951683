import { FC, useEffect } from 'react';
import { Stack } from '@mui/material';

import DetailPanel from 'src/features/lobby/components/DetailPanel';
import HeaderBanner from 'src/ui/HeaderBanner';
import RecentProjectsList from 'src/features/lobby/components/RecentProjectsList';
import { ProjectEditContextProvider } from 'src/features/lobby/components/ProjectEditModal';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { setPageIsLoaded } from 'src/store/loading';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { ProductCreateContextProvider } from 'src/features/lobby/components/ProductCreateModal';

const Home: FC = () => {
  useDocumentTitle('Home');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageIsLoaded(false));
  }, [dispatch]);

  return (
    <ProjectEditContextProvider>
      <ProductCreateContextProvider>
        <Stack sx={{ flex: 1 }} direction="row">
          <Stack sx={{ flex: 1 }}>
            <HeaderBanner />
            <RecentProjectsList onLoaded={() => dispatch(setPageIsLoaded(true))} />
          </Stack>

          <DetailPanel />
        </Stack>
      </ProductCreateContextProvider>
    </ProjectEditContextProvider>
  );
};

export default Home;
