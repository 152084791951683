import { useCallback } from 'react';
import pluralize from 'pluralize';
import { openConfirmDialog } from 'src/ui/Dialog';
import { AdminItemType } from 'src/features/admin/types';

export const useDeleteConfirmation = (
  deleteFn: () => Promise<void>,
  deleteType: 'group' | 'user',
  itemType: AdminItemType,
  selectedItemsNumber?: number
): { openDeleteConfirmation: () => void } => {
  const openDeleteConfirmation = useCallback((): void => {
    openConfirmDialog({
      title: 'Are you sure?',
      content: `This will permanently remove the selected ${pluralize(deleteType, 0)} from the ${itemType}`,
      onOk: async ({ update, preventAutoClose }) => {
        try {
          await deleteFn();
        } catch (e) {
          preventAutoClose();
          let content = `Something went wrong with deleting the ${deleteType}`;
          if ((e as { status: number })?.status === 409 && deleteType === 'group') {
            content = `${pluralize('Group', selectedItemsNumber)} you are trying to delete contain${
              selectedItemsNumber === 1 ? 's' : ''
            } users`;
          }
          update({ message: { severity: 'error', content } });
        }
      },
    });
  }, [deleteFn, deleteType, itemType, selectedItemsNumber]);

  return { openDeleteConfirmation };
};
