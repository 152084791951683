interface HelperLink {
  label: string;
  link?: string;
  id: string;
}

export const HELPER_LINKS: Readonly<HelperLink[]> = [
  {
    label: 'Moata Community',
    link: 'https://support.moata.com/hc/en-gb/community/topics',
    id: 'community-link',
  },
  {
    label: 'Moata Support',
    link: 'https://support.moata.com/hc/en-gb',
    id: 'support-link',
  },
];
