/* eslint @typescript-eslint/no-duplicate-enum-values: "error" */

export enum GTMEventName {
  /* Header */
  HeaderLogoClick = 'header-logo-click',
  HeaderNavItemClick = 'header-nav-item-click',
  HeaderSupportButtonClick = 'header-support-button-click',
  HeaderSupportItemClick = 'header-support-item-click',
  HeaderAccountButtonClick = 'header-account-button-click',
  HeaderAccountItemLogoutClick = 'header-account-item-logout-click',
}
