import { FC } from 'react';
import { Stack, IconButton, Divider } from '@mui/material';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';

import { StdDetailPanelProps } from '../types';

const StdDetailPanel: FC<StdDetailPanelProps> = ({ getDetailPanelContent, handlePanelClose }) => {
  return (
    <Stack
      sx={{
        border: '0.5px solid',
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}
      data-testid="detail-panel"
    >
      <Stack>
        <Stack p={0.5} alignItems="flex-end">
          <IconButton
            size="small"
            color="inherit"
            onClick={handlePanelClose}
            title="Close"
            data-gtm-detail-close="std"
            data-testid="detail-panel-close-button"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Divider />

        <Stack width={287}>{getDetailPanelContent()}</Stack>
      </Stack>
    </Stack>
  );
};

export default StdDetailPanel;
