import sample from 'lodash.sample';
import { BannerData } from '../types';

/**
 * Returns a random single bannerData object from an array of bannerData's.
 * The array is filtered based on the supplied page param.
 * @param bannersArray an array of bannerData objects
 * @param page enum string, defines how the array is filtered before returning the random bannerData
 * @returns bannerData object
 */
const getBanner = (bannersArray: BannerData[], page: 'home' | 'marketplace'): BannerData | undefined =>
  sample(bannersArray.filter((banner: BannerData) => banner.page?.includes(page)));

export default getBanner;
