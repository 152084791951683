import z from 'zod';
import { ProjectUsageTypes } from 'src/features/lobby/components/ProjectEditModal/constants';
import { isUrl } from 'src/utils/url';
import { isAfterToday } from './helpers/isAfterToday';

const urlSchema = z.union([
  z.string().max(1000).refine(isUrl, { message: 'Please use a valid URL' }).optional(),
  z.literal(''),
  z.null(),
]);

const projectFormSchema = z
  .object({
    name: z
      .string()
      .trim()
      .min(3, 'Name must be at least 3 characters.')
      .max(39, 'Name must be less than 39 characters'),
    productId: z.number().int().positive(),
    subHeader: z.union([z.string().trim().max(1000).optional(), z.null()]),
    usageType: z.string(),
    contractId: z.union([z.string().optional(), z.null()]),
    validTill: z
      .string()
      // Note:
      // because the date string returned from the API is NOT a valid UTC ISO string,
      // we can NOT use `.datetime()` here
      // .datetime()
      .optional()
      .refine((date) => !date || isAfterToday(date), {
        message: 'Valid Until date must be after today',
      }),
    region: z.string(),
    imageUrl: urlSchema,
    projectSource: urlSchema,
    projectSummaryUrl: urlSchema,
    newsListUrl: urlSchema,
    projectTermsAndConditionsUrl: urlSchema,
  })
  .refine(
    (data) => (data.usageType === ProjectUsageTypes.External ? data.contractId && data.contractId !== '' : true),
    {
      path: ['contractId'],
      message: "This field is required, if usage type 'External' is selected",
    }
  );

export default projectFormSchema;
