import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import GlobalLobbyLayout from './GlobalLobbyLayout';

const GlobalLobbyLayoutRoute: FC = () => {
  return (
    <GlobalLobbyLayout>
      <Outlet />
    </GlobalLobbyLayout>
  );
};

export default GlobalLobbyLayoutRoute;
