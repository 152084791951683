import { FC, ReactNode, SyntheticEvent, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { ProjectMetadata } from '@h2know-how/identity-sdk';
import useGetScreenSize from 'src/hooks/useGetScreenSize';

import DetailsTab from './DetailsTab';

interface Props {
  projectSummary: ReactNode;
  admins?: ProjectMetadata['admins'];
  projectId: ProjectMetadata['projectId'];
  isCurrentUserAdmin: boolean;
}

// exported for testing
export const getVariant = (mediumSize: boolean): 'fullWidth' | undefined => {
  return !mediumSize ? 'fullWidth' : undefined;
};

const TabsContent: FC<Props> = ({ projectSummary, admins, projectId, isCurrentUserAdmin }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { isMd } = useGetScreenSize('up');

  const handleTabSwitch = (_: SyntheticEvent, newTabIndex: number): void => {
    setTabIndex(newTabIndex);
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        value={tabIndex}
        onChange={handleTabSwitch}
        aria-label="tabs"
        variant={getVariant(isMd)}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Tab label="Details" id="tabs-0" aria-controls="tabs-0" data-gtm-detail-panel="details-tab" />
      </Tabs>

      {tabIndex === 0 && (
        <DetailsTab
          projectSummary={projectSummary}
          admins={admins}
          projectId={projectId}
          isCurrentUserAdmin={isCurrentUserAdmin}
        />
      )}
    </>
  );
};

export default TabsContent;
