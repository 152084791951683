import { styled } from '@mui/material/styles';
import TableSortLabel, { tableSortLabelClasses } from '@mui/material/TableSortLabel';

export const HeaderSortLabel = styled(TableSortLabel)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
  '&:focus': {
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableSortLabelClasses.active}`]: {
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableSortLabelClasses.active} .${tableSortLabelClasses.icon}`]: {
    color: theme.palette.primary.contrastText,
  },
}));
