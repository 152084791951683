import { ComponentProps, FC } from 'react';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import NavLink from './NavLink';

const RouterNavLink: FC<
  Omit<ComponentProps<typeof NavLink>, 'component'> & ComponentProps<typeof ReactRouterNavLink>
> = (props) => {
  return <NavLink {...props} role="link" component={ReactRouterNavLink} />;
};

export default RouterNavLink;
