import { FC } from 'react';
import { Result } from '../../Result';

const NoSearchResult: FC = () => {
  return (
    <Result
      severity="info"
      title="No results found for your search terms"
      subtitle="Please check your spelling, or try using fewer, different or more general keywords."
    />
  );
};

export default NoSearchResult;
