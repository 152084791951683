import { Card, CardActions, CardContent, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface TermsAndConditionsTileProps {
  title: string;
  description: string;
  url: string;
}

export const TermsAndConditionsTile: FC<TermsAndConditionsTileProps> = ({ title, description, url }) => {
  return (
    <Card variant="outlined" component={Stack}>
      <CardContent>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
      <CardActions sx={{ mt: 'auto' }}>
        <Link
          variant="button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          title={`Open ${title} in a new window`}
        >
          Read {title}
        </Link>
      </CardActions>
    </Card>
  );
};
