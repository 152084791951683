import { ProjectUsers } from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions } from 'react-query';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';
import { PRODUCT_USER_BASE_QUERY_KEY } from './constants';

export const ProductUserAPI = {
  queryKeys: {
    base: PRODUCT_USER_BASE_QUERY_KEY,
    byProductId: (productId: number) => [PRODUCT_USER_BASE_QUERY_KEY, productId] as QueryKey,
    list: (productId: number) =>
      [...ProductUserAPI.queryKeys.byProductId(productId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useGetAll: (productId: number, options?: UseQueryOptions<ProjectUsers, SerializedError>) => {
    return { data: [], isLoading: false, error: undefined, isError: false };
    // TODO implement this hook properly when API endpoint is ready to get all product users
    // const { getApiToken } = useAuth();
    //
    // return useQuery(
    //   ProductUserAPI.queryKeys.list(productId),
    //   async ({ signal }) =>
    //     authorisedIdentityApiClient(await getApiToken()).ProductUser.getAll(productId, { signal }),
    //   options
    // );
  },
};
