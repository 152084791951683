import { FC, forwardRef, ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
  columns: number;
  children: ReactNode;
}

/**
 * A grid that uses the number of columns you give it
 */
const ControlledGrid: FC<Props> = forwardRef(({ children, columns }: Props, ref) => (
  <Box ref={ref} display="grid" p={3} gap={3} gridTemplateColumns={`repeat(${columns}, 1fr)`}>
    {children}
  </Box>
));

export default ControlledGrid;
