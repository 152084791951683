import { useState, FC, FormEvent } from 'react';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import { Link, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useRedirectIfLoggedIn from 'src/features/auth/hooks/useRedirectIfLoggedIn';
import { login } from 'src/store/auth';
import validateEmail from 'src/utils/validateEmail';
import useAsyncThunkDispatch from 'src/hooks/useAsyncThunkDispatch';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { AppFullRoutePath, AppPage, LoginPageSearchParam } from 'src/router';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { useAppSelector } from 'src/store/useAppSelector';
import useAuth from 'src/hooks/useAuth';
import { sendRedirectUriUsageEvent } from 'src/services/telemetry/sentry/events';
import { AuthLoadingSpinner } from 'src/features/auth/components/AuthLoadingSpinner/AuthLoadingSpinner';

const Login: FC = () => {
  useDocumentTitle('Login');

  const { isXs } = useGetScreenSize('only');

  const { isAuthenticated } = useAuth();

  const [urlSearchParams] = useSearchParams();

  const hasThirdPartyRedirectUri = urlSearchParams.has(LoginPageSearchParam.RedirectUri);
  if (hasThirdPartyRedirectUri) {
    sendRedirectUriUsageEvent({ landingUrl: window.location.href, referrerUrl: document.referrer });
  }

  // abort the login request when the searchParam change, or when we unmount
  const { dispatch: dispatchLogin, isPending } = useAsyncThunkDispatch(login, {
    abortOnChange: [urlSearchParams],
  });

  // if the search params have custom redirect uri, we should allow this page
  // even if we are logged in
  // - it may result in the logging in of a different user
  // - it will not affect the auth state of this app as after a login attempt
  //   this app will not be responsible for handling the callback
  useRedirectIfLoggedIn({ disabled: hasThirdPartyRedirectUri, handleRedirectSearchParamsFromPage: AppPage.Login });

  const [email, setEmail] = useState('');
  const [isEmailFocused, setEmailIsFocussed] = useState(false);
  const validationErrors = validateEmail(email);

  const isRedirectingToAuthServer = useAppSelector((state) => state.auth.redirectingToAuthServer);
  const isSubmitLoginProcessing = isPending || isRedirectingToAuthServer;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    await dispatchLogin({ email, urlSearchParams });
  };

  return !hasThirdPartyRedirectUri && isAuthenticated ? (
    <AuthLoadingSpinner />
  ) : (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{
        p: isXs ? 2 : 4,
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Typography>Welcome to Moata, please enter your email</Typography>
      <TextField
        label="Your email"
        variant="outlined"
        type="email"
        name="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onFocus={() => setEmailIsFocussed(true)}
        onBlur={() => setEmailIsFocussed(false)}
        autoFocus
        disabled={isSubmitLoginProcessing}
        error={!isEmailFocused && validationErrors !== undefined}
        helperText={!isEmailFocused && validationErrors}
        fullWidth
      />
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent={isXs ? 'flex-end' : 'space-between'}
        alignItems={isXs ? 'stretch' : 'center'}
        sx={{ pt: isXs ? 0 : 2 }}
      >
        <Stack direction={isXs ? 'row' : 'column'} gap={isXs ? 1 : 0.5} sx={{ pb: isXs ? 2 : 0 }}>
          <Link
            component={RouterLink}
            to={`${AppFullRoutePath[AppPage.TermsAndConditions]}`}
            underline="always"
            color="text.primary"
          >
            Terms & Conditions
          </Link>
          <Link
            component={RouterLink}
            to={`${AppFullRoutePath[AppPage.TermsAndConditions]}`}
            underline="always"
            color="text.primary"
          >
            Privacy Policy
          </Link>
        </Stack>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitLoginProcessing}
          disabled={!email || !!validationErrors}
        >
          Next
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default Login;
