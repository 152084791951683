import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from 'src/configs';

export const initializeSentry = (): void => {
  if (!SENTRY_DSN) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    release: BUILD_NUMBER,
    // For errors.
    sampleRate: 1.0,
    // For general traces, like Performance Monitoring, etc.
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
