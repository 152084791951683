import { configureStore } from '@reduxjs/toolkit';

import moataSdkRequestErrorHandling from './middleware/moataSdkRequestErrorHandling';
import reducer from './rootReducer';

type ConfigureStoreOptions = Parameters<typeof configureStore>[0];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createStore = (options?: Partial<Pick<ConfigureStoreOptions, 'preloadedState'>>) => {
  const store = configureStore({
    ...options,
    reducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: { ignoredActions: ['persist/PERSIST'] },
      }).concat([moataSdkRequestErrorHandling]),
  });

  return store;
};
