import { ProductAPI } from 'src/services/api/ProductAPI';
import { useCurrentProductId } from './useCurrentProductId';

export const useCurrentProduct = (
  options?: Parameters<typeof ProductAPI.useGetById>[1]
): ReturnType<typeof ProductAPI.useGetById> => {
  const productId = useCurrentProductId();

  return ProductAPI.useGetById(Number(productId), { enabled: !!productId, ...options });
};
