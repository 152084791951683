import {
  ProjectGroupBaseDTO,
  ProjectGroupCreateDTO,
  ProjectGroupDTO,
  ProjectGroupUpdateDTO,
} from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { PROJECT_GROUP_BASE_QUERY_KEY } from 'src/services/api/ProjectGroupAPI/constants';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';

export const ProjectGroupAPI = {
  queryKeys: {
    base: PROJECT_GROUP_BASE_QUERY_KEY,
    byProjectId: (projectId: number) => [PROJECT_GROUP_BASE_QUERY_KEY, projectId] as QueryKey,
    list: (projectId: number) =>
      [...ProjectGroupAPI.queryKeys.byProjectId(projectId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
    detail: (projectId: number, groupId: string) =>
      [...ProjectGroupAPI.queryKeys.byProjectId(projectId), QUERY_KEY_VARIANTS.DETAIL, groupId] as QueryKey,
  },
  useCreate: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ projectId, payload }: { projectId: number; payload: ProjectGroupCreateDTO }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectGroup.create(projectId, payload),
      onSuccess: async (_, { projectId }) => {
        await queryClient.invalidateQueries(ProjectGroupAPI.queryKeys.byProjectId(projectId));
      },
    });
  },
  useUpdate: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({
        groupId,
        projectId,
        payload,
      }: {
        projectId: number;
        groupId: string;
        payload: ProjectGroupUpdateDTO;
      }) => authorisedIdentityApiClient(await getApiToken()).ProjectGroup.update(projectId, groupId, payload),
      onSuccess: async (_, { projectId }) => {
        await queryClient.invalidateQueries(ProjectGroupAPI.queryKeys.byProjectId(projectId));
      },
    });
  },
  useDeleteMany: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ groupIds, projectId }: { groupIds: string[]; projectId: number }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectGroup.deleteMany(projectId, groupIds),
      onSuccess: async (_, { projectId }) => {
        await queryClient.invalidateQueries(ProjectGroupAPI.queryKeys.byProjectId(projectId));
      },
    });
  },
  useGetById: (projectId: number, groupId: string, options?: UseQueryOptions<ProjectGroupDTO, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectGroupAPI.queryKeys.detail(projectId, groupId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectGroup.getById(projectId, groupId, { signal }),
      options
    );
  },
  useGetByProjectId: (projectId: number, options?: UseQueryOptions<ProjectGroupBaseDTO[], SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectGroupAPI.queryKeys.list(projectId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectGroup.getAllByProjectId(projectId, { signal }),
      options
    );
  },
  usePutActions: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({
        projectId,
        groupId,
        actionIds,
      }: {
        projectId: number;
        groupId: string;
        actionIds: string[];
      }) => authorisedIdentityApiClient(await getApiToken()).ProjectGroup.putActions(projectId, groupId, actionIds),
      onSuccess: async (_, { projectId, groupId }) => {
        await queryClient.invalidateQueries(ProjectGroupAPI.queryKeys.detail(projectId, groupId));
      },
    });
  },
};
