/* eslint-disable react/no-array-index-key */
import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

interface ListLoadingSkeletonProps {
  rows?: number;
}

const DEFAULT_NUMBER_OF_ROWS = 10;

export const ListLoadingSkeleton: FC<ListLoadingSkeletonProps> = ({ rows = DEFAULT_NUMBER_OF_ROWS }) => (
  <Stack spacing={2}>
    {[...Array(rows)].map((_, i) => (
      <Skeleton key={i} variant="rectangular" />
    ))}
  </Stack>
);
