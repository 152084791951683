import pupa from 'pupa';

// DO NOT change the values.
// They are defined by pupa.
// We need to use the same values to make sure that the types are compatible.
const START = '{' as const;
const END = '}' as const;

type FallbackParams = Record<string, unknown> | unknown[];

type ParamName<Name> = Name extends Exclude<Name, ''> ? Name : never;
type ParamNames<Template> = Template extends `${string}${typeof START}${infer Name}${typeof END}${infer Rest}`
  ? ParamName<Name> | ParamNames<Rest>
  : never;
type TemplateParams<Template> = Template extends `${string}${typeof START}${string}${typeof END}${string}`
  ? {
      [Key in ParamNames<Template>]: string | number;
    }
  : FallbackParams;

/**
 * Renders a placeholder template with the given data.
 *
 * Usage:
 *  - renderPlaceholderTemplate('{0}, {1}!', ['Hello', 'world']) // Hello, world!
 *  - renderPlaceholderTemplate('{one}, {two}!', { one: 'Hello', two: 'world' }) // Hello, world!
 *  - renderPlaceholderTemplate('{one.two}!', { one: { two: 'Hello' } }) // Hello!
 */
export const renderPlaceholderTemplate = <T extends string>(
  template: T,
  params: TemplateParams<T>,
  options?: Pick<NonNullable<Parameters<typeof pupa>[2]>, 'transform'>
): string =>
  pupa(template, params, {
    // do not throw an error when a placeholder resolves to undefined, leave the placeholder as is.
    ignoreMissing: true,
    // normalize null values to undefined, so that they can be ignored.
    transform: options?.transform
      ? (data) => options.transform?.(data) ?? undefined
      : (data) => data.value ?? undefined,
  });
