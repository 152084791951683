const validateName = (value?: string): string | undefined => {
  if (value) {
    const regExp = /^[A-Za-z -]+$/;

    return regExp.test(value) ? value : undefined;
  }
  return undefined;
};

export default validateName;
