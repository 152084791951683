import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-use';
import { ItemManagementController, ListItem } from 'src/features/admin/components/ItemsListManageModal/types';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';

export const useProjectUserGroupController = (projectId: number, userId: string): ItemManagementController => {
  const [isDirty, setIsDirty] = useState(false);

  const {
    data: projectGroups,
    isLoading: isLoadingProjectGroups,
    error: projectGroupsError,
  } = ProjectGroupAPI.useGetByProjectId(projectId);

  const [requestError, setRequestError] = useState<string>();
  const { mutateAsync: updateUser, isLoading: isUpdateLoading, isError: isUpdateError } = ProjectUserAPI.useSetGroups();

  const groupItems = useMemo(() => projectGroups?.map(({ name, groupId }) => ({ name, id: groupId })), [projectGroups]);

  const { data: user, isLoading: isLoadingUser, error: userError } = ProjectUserAPI.useGetById(projectId, userId);

  const [newUserGroups, { set, setAll, remove }] = useMap<Record<string, ListItem>>();
  const userGroups = useMemo(() => Object.values(newUserGroups), [newUserGroups]);

  const error = useMemo(
    () =>
      requestError ||
      (isUpdateError || projectGroupsError || userError
        ? 'There was an error updating groups of this user. Please try again.'
        : undefined),
    [isUpdateError, projectGroupsError, requestError, userError]
  );

  const isLoading = isUpdateLoading || isLoadingProjectGroups || isLoadingUser;

  const init = useCallback(() => {
    if (user && groupItems) {
      const initialUserGroups = groupItems.filter((group) => {
        return user?.groups.includes(group.id);
      });
      setAll(initialUserGroups.reduce((acc, group) => ({ ...acc, [group.id]: group }), {}));
    }
  }, [groupItems, setAll, user]);

  useEffect(() => {
    init();
  }, [init]);

  // Prospects are projects groups the user is not a part of.
  const groupProspects = useMemo(
    () => groupItems?.filter(({ id, name }) => name && !newUserGroups[id]) ?? [],
    [newUserGroups, groupItems]
  );

  const addItems = useCallback(
    (groupIds: string[]) => {
      setIsDirty(true);
      groupIds.forEach((groupId) => {
        const group = groupProspects.find(({ id }) => id === groupId);
        if (group) set(groupId, group);
      });
    },
    [groupProspects, set]
  );

  const removeItems = useCallback(
    (userIds: string[]) => {
      setIsDirty(true);
      userIds.forEach((id) => remove(id));
    },
    [remove]
  );

  const onUpdate = useCallback(async (): Promise<boolean> => {
    if (requestError) setRequestError(undefined);
    if (userGroups.length === 0) {
      setRequestError('Users must be a member of at least one group.');
      return false;
    }
    await updateUser({ projectId, userId, groupIds: userGroups.map(({ id }) => id) });
    return true;
  }, [updateUser, projectId, requestError, userGroups, userId]);

  const controller = useMemo(
    () => ({
      isDirty,
      isLoadingMembers: isLoadingUser,
      isLoading,
      error,
      memberItems: userGroups,
      itemProspects: groupProspects,
      addItems,
      removeItems,
      onUpdate,
      reset: init,
    }),
    [isDirty, isLoadingUser, isLoading, error, userGroups, groupProspects, addItems, removeItems, onUpdate, init]
  );

  return controller;
};
