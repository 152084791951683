import {
  DeleteUsersDTO,
  InviteUsersDTO,
  ProjectUserDTO,
  ProjectUserSimpleDTO,
  ProjectUsers,
} from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { PROJECT_USER_BASE_QUERY_KEY } from 'src/services/api/ProjectUserAPI/constants';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';
import { UserAPI } from 'src/services/api/UserAPI';

export const ProjectUserAPI = {
  queryKeys: {
    base: PROJECT_USER_BASE_QUERY_KEY,
    byProjectId: (projectId: number) => [PROJECT_USER_BASE_QUERY_KEY, projectId] as QueryKey,
    list: (projectId: number) =>
      [...ProjectUserAPI.queryKeys.byProjectId(projectId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
    detail: (projectId: number, userId: string) =>
      [...ProjectUserAPI.queryKeys.byProjectId(projectId), QUERY_KEY_VARIANTS.DETAIL, userId] as QueryKey,
    groupList: (projectId: number, groupId: string) => [ProjectUserAPI.queryKeys.list(projectId), groupId] as QueryKey,
  },
  useGetById: (projectId: number, userId: string, options?: UseQueryOptions<ProjectUserDTO, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectUserAPI.queryKeys.detail(projectId, userId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.getById(projectId, userId, { signal }),
      options
    );
  },
  useGetByProjectId: (projectId: number, options?: UseQueryOptions<ProjectUsers, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectUserAPI.queryKeys.list(projectId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.getAllByProjectId(projectId, { signal }),
      options
    );
  },
  useSetUsersByGroupId: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ projectId, groupId, userIds }: { projectId: number; groupId: string; userIds: string[] }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectGroup.setUsers(projectId, groupId, userIds),
      onSuccess: async (_, { projectId, groupId }) => {
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.groupList(projectId, groupId));
      },
    });
  },
  useGetByGroupId: (
    projectId: number,
    groupId: string,
    options?: UseQueryOptions<ProjectUserSimpleDTO[], SerializedError>
  ) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectUserAPI.queryKeys.groupList(projectId, groupId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.getAllByGroupId(projectId, groupId, { signal }),
      options
    );
  },
  useSetGroups: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; userId: string; groupIds: string[] }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.setGroups(
          data.projectId,
          data.userId,
          data.groupIds
        ),
      onSuccess: async (_, { projectId }) => {
        // The list contains the number of members a user belongs, so just invalidate all
        // project user data for the project.
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.byProjectId(projectId));
        await queryClient.invalidateQueries({
          queryKey: UserAPI.queryKeys.projects,
        });
      },
    });
  },
  useRemoveFromGroup: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; userId: string; groupIds: string[] }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.removeFromGroup(
          data.projectId,
          data.userId,
          data.groupIds
        ),
      onSuccess: async (_, { projectId, groupIds }) => {
        groupIds.forEach((groupId) =>
          queryClient.invalidateQueries(ProjectGroupAPI.queryKeys.detail(projectId, groupId))
        );
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.byProjectId(projectId));
      },
    });
  },
  useGrantAdmin: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; userId: string }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.grantAdmin(data.projectId, data.userId),
      onSuccess: async (_, { projectId, userId }) => {
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.detail(projectId, userId));
        await queryClient.invalidateQueries({
          queryKey: UserAPI.queryKeys.projects,
        });
      },
    });
  },
  useRevokeAdmin: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; userId: string }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.revokeAdmin(data.projectId, data.userId),
      onSuccess: async (_, { projectId, userId }) => {
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.detail(projectId, userId));
        await queryClient.invalidateQueries({
          queryKey: UserAPI.queryKeys.projects,
        });
      },
    });
  },
  useInviteUsers: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; payload: InviteUsersDTO }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.invite(data.projectId, data.payload),
      onSuccess: async (_, { projectId }) => {
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.list(projectId));
      },
    });
  },
  useDeleteMany: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (data: { projectId: number; payload: DeleteUsersDTO }) =>
        authorisedIdentityApiClient(await getApiToken()).ProjectUser.deleteMany(data.projectId, data.payload),
      onSuccess: async (_, { projectId }) => {
        await queryClient.invalidateQueries(ProjectUserAPI.queryKeys.byProjectId(projectId));
      },
    });
  },
};
