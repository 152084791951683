import { ReactElement } from 'react';
import { HTMLReactParserOptions, domToReact, Element, attributesToProps } from 'html-react-parser';
import { Typography, List, ListItem, Link } from '@mui/material';

// exported so it can be tested separately
export const replaceDomNode = (domNode: Element): ReactElement | undefined => {
  if (domNode.name === 'p') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <Typography paragraph>{domToReact(domNode.children, htmlParseOptions)}</Typography>;
  }

  if (domNode.name === 'strong') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <b>{domToReact(domNode.children)}</b>;
  }

  if (domNode.name === 'a') {
    const props = attributesToProps(domNode.attribs);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <Link {...props}>{domToReact(domNode.children, htmlParseOptions)}</Link>;
  }

  if (domNode.name === 'li') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <ListItem>{domToReact(domNode.children, htmlParseOptions)}</ListItem>;
  }

  if (domNode.name === 'ul' || domNode.name === 'ol') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <List>{domToReact(domNode.children, htmlParseOptions)}</List>;
  }

  return undefined;
};

const htmlParseOptions: HTMLReactParserOptions = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - passing it as an Element so TS doesn't complain when we access domNode.name etc.
  replace: replaceDomNode,
  trim: true,
};

export default htmlParseOptions;
