import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextField } from '@mui/material';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/useAppDispatch';
import Modal from 'src/ui/Modal';
import { setMessage } from 'src/store/messages';
import { isConflictErrorGuard } from 'src/services/api/helpers';
import { AdminItemType } from 'src/features/admin/types';
import { useGroupCreate } from './hooks/useGroupCreate';
import groupFormSchema from './schema';

interface ProjectEditModalProps {
  type: AdminItemType;
  open: boolean;
  onClose: () => void;
  itemId: number;
}
const GroupCreateModal: FC<ProjectEditModalProps> = ({ open, onClose, itemId, type }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({ resolver: zodResolver(groupFormSchema) });

  const { create, isLoading, error } = useGroupCreate(type, itemId);

  const isConflictError = isConflictErrorGuard(error);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await create(formData.name);
      dispatch(setMessage({ severity: 'success', content: `${formData.name} created` }));
      handleClose();
    } catch (e) {
      if (e instanceof Error && 'status' in e && e.status === 409) {
        setError('name', { message: 'There is already a group with this name' });
      }
    }
  });

  return (
    <Modal
      closable
      message={
        error && !isConflictError
          ? {
              content: 'There was an error creating the group',
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={handleClose}
      title="New Group"
    >
      <Modal.Content>
        <form data-testid={`${type}-group-creation-modal`} onSubmit={onSubmit}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                value={field.value ?? ''}
                autoFocus
                label="Group name"
                placeholder="Name between 5 and 50 characters"
                required
                error={!!errors.name}
                helperText={errors.name ? String(errors.name.message) : undefined}
              />
            )}
          />
          <Modal.Actions>
            <Button disabled={isLoading} variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" color="primary" type="submit" disabled={!isDirty} loading={isLoading}>
              Create
            </LoadingButton>
          </Modal.Actions>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default GroupCreateModal;
