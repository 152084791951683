import z from 'zod';
import { isUrl } from 'src/utils/url';

const productFormSchema = z.object({
  name: z.string().trim().min(3, 'Name must be at least 3 characters').max(39, 'Name must be less than 39 characters'),
  shortName: z.string().trim().length(2, 'Short name must be 2 characters'),
  productContentUrl: z.union([
    z.string().max(1000).refine(isUrl, { message: 'Please use a valid URL' }).optional(),
    z.literal(''),
  ]),
  productAdmin: z.union([z.string().email().optional(), z.literal('')]),
  visible: z.boolean(),
});

export default productFormSchema;
