import { GTMEventName } from '../../enums';
import type { DataLayerEventCreator } from '../../types';

export const logoClick: DataLayerEventCreator = () => ({
  dataLayer: {
    event: GTMEventName.HeaderLogoClick,
  },
});

export const navItemClick: DataLayerEventCreator<{
  target: 'home' | 'marketplace' | 'products';
}> = (props) => ({
  dataLayer: {
    event: GTMEventName.HeaderNavItemClick,
    ...props,
  },
});

export const supportButtonClick: DataLayerEventCreator = () => ({
  dataLayer: {
    event: GTMEventName.HeaderSupportButtonClick,
  },
});

export const supportItemClick: DataLayerEventCreator<{ name: string }> = ({ name }) => ({
  dataLayer: {
    event: GTMEventName.HeaderSupportItemClick,
    name,
  },
});

export const accountButtonClick: DataLayerEventCreator = () => ({
  dataLayer: {
    event: GTMEventName.HeaderAccountButtonClick,
  },
});

export const accountItemLogoutClick: DataLayerEventCreator = () => ({
  dataLayer: {
    event: GTMEventName.HeaderAccountItemLogoutClick,
  },
});
