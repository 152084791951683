import { createSlice } from '@reduxjs/toolkit';

import { DetailPanelProps, DetailPanelState } from './types';

export const initialState: DetailPanelState = {
  props: undefined,
};

const slice = createSlice({
  name: 'identity/detailPanel',
  initialState,
  reducers: {
    setDetailPanelProps: (state, { payload: props }: { payload: DetailPanelProps }) => {
      state.props = props;
    },
    clearDetailPanelProps: (state) => {
      state.props = undefined;
    },
  },
});

export const { setDetailPanelProps, clearDetailPanelProps } = slice.actions;

export const { reducer } = slice;
