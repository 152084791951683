export enum ProjectUsageTypes {
  External = 'External',
  Demo = 'Demo',
  Internal = 'Internal',
}

export const PROJECT_REGIONS = ['EUNA', 'NASA', 'ISMA', 'MMBC', 'APNA', 'GRPS'];

export enum TermsAndConditionsType {
  Default = 'Default',
  MGOBridgeMonitoring = 'MGOBridgeMonitoring',
  MGORailMonitoring = 'MGORailMonitoring',
  MISpecific = 'MISpecific',
}

export const TermsAndConditionsName: Record<TermsAndConditionsType, string> = {
  [TermsAndConditionsType.Default]: 'Default',
  [TermsAndConditionsType.MGOBridgeMonitoring]: 'MGO Bridge Monitoring',
  [TermsAndConditionsType.MGORailMonitoring]: 'MGO Rail Monitoring',
  [TermsAndConditionsType.MISpecific]: 'MI Specific',
};
