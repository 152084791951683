import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AuthLayout from './AuthLayout';

const AuthLayoutRoute: FC = () => {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default AuthLayoutRoute;
