import { AppFullRoutePath, AppPage } from 'src/router';

type Page = {
  linkName: string;
  linkUrl: string;
  index: number;
  navItemTarget: 'home' | 'marketplace' | 'products';
};

const pages: Page[] = [
  {
    linkName: 'Home',
    linkUrl: AppFullRoutePath[AppPage.Home],
    index: 0,
    navItemTarget: 'home',
  },
  {
    linkName: 'Marketplace',
    linkUrl: AppFullRoutePath[AppPage.MarketPlace],
    index: 1,
    navItemTarget: 'marketplace',
  },
  // TODO enable this test when product admin bugs are fixed
  // {
  //   linkName: 'Products',
  //   linkUrl: AppFullRoutePath[AppPage.AdminProducts],
  //   index: 2,
  //   navItemTarget: 'products',
  // },
];

export default pages;
