import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { initializeSentry } from './services/telemetry/sentry';
import { initializeGTM } from './services/telemetry/gtm';
import { initializeChameleon } from './services/telemetry/chameleon';
import App from './App';

// Initialize Sentry before everything at the root level
initializeSentry();
initializeGTM();
initializeChameleon();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
