import { ActionHandlerArg, openConfirmDialog } from './openDialog';

export function openDiscardChangesConfirmDialog({
  onDiscard,
  isDirty,
}: {
  onDiscard: VoidFunction;
  isDirty?: boolean;
}): ActionHandlerArg | void {
  return isDirty == null || isDirty
    ? openConfirmDialog({
        title: 'Are you sure?',
        content: 'You have unsaved changes. This will discard your changes.',
        okText: 'Keep my changes',
        cancelText: 'Discard my changes',
        onCancel: onDiscard,
      })
    : onDiscard();
}

export const openAreYouSureConfirmDialog = <T>({
  warningText,
  errorText,
  onConfirm,
}: {
  warningText: string;
  errorText: string;
  onConfirm: () => Promise<T> | void;
}): ActionHandlerArg =>
  openConfirmDialog({
    title: 'Are you sure?',
    content: `This will ${warningText}`,
    onOk: async ({ update, preventAutoClose }) => {
      try {
        await onConfirm();
      } catch (e) {
        preventAutoClose();
        update({ message: { severity: 'error', content: `Something went wrong with ${errorText}` } });
      }
    },
  });
