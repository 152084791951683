import { createSlice } from '@reduxjs/toolkit';

import { MessagesState, Message } from './types';

export const initialState: MessagesState = {
  message: undefined,
};

const slice = createSlice({
  name: 'identity/messages',
  initialState,
  reducers: {
    setMessage: (state, { payload: message }: { payload: Message }) => {
      state.message = message;
    },
    clearMessage: (state) => {
      state.message = undefined;
    },
  },
});

export const { setMessage, clearMessage } = slice.actions;

export const { reducer } = slice;
