/**
 * Parses a value to valid `number` (excluded `NaN`) or `undefined`.
 *
 * string value needs to strictly match a number expression after trimmed.
 */
export const toValidNumberOrUndefined = (value: number | string | undefined): number | undefined => {
  let validNumberOrNaN: number;

  if (typeof value === 'number') {
    validNumberOrNaN = value;
  } else if (typeof value === 'string') {
    validNumberOrNaN = Number(value.trim() || NaN);
  } else {
    validNumberOrNaN = NaN;
  }

  return !Number.isNaN(validNumberOrNaN) ? validNumberOrNaN : undefined;
};
