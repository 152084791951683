import type { FC, ReactNode } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import { Alert, AlertProps } from '@mui/material';
import useGetScreenSize from 'src/hooks/useGetScreenSize';

interface ModalProps extends Omit<DialogProps, 'classes'> {
  title: string;
  closable?: boolean;
  onCancel?: VoidFunction;
  message?: {
    content?: ReactNode;
    severity?: AlertProps['severity'];
  };
}

const Modal: FC<ModalProps> & {
  Content: typeof DialogContent;
  ContentText: typeof DialogContentText;
  Actions: typeof DialogActions;
} = ({ title, closable = true, onCancel, message, children, ...dialogProps }) => {
  const { isXs } = useGetScreenSize('only');

  return (
    <Dialog PaperProps={{ sx: { minWidth: isXs ? '280px' : '375px', maxWidth: '100%' } }} {...dialogProps}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {title}
        {closable ? (
          <IconButton sx={{ marginLeft: 'auto' }} size="small" aria-label="close" onClick={onCancel}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null}
      </DialogTitle>
      {message?.content ? (
        <Alert variant="filled" severity={message.severity}>
          {message.content}
        </Alert>
      ) : null}
      {children}
    </Dialog>
  );
};

Modal.Content = DialogContent;
Modal.ContentText = DialogContentText;
Modal.Actions = DialogActions;

export default Modal;
