import { useMemo } from 'react';
import { useTitle } from 'react-use';

const DOCUMENT_TITLE_SEPARATOR = ' - ';
const DOCUMENT_TITLE_SUFFIX = 'Moata Solutions';

export const useDocumentTitle = (title?: string | string[]): void => {
  const newTitle = useMemo(
    () =>
      [...(Array.isArray(title) ? title : [title]), DOCUMENT_TITLE_SUFFIX]
        .filter(Boolean)
        .join(DOCUMENT_TITLE_SEPARATOR),
    [title]
  );

  useTitle(newTitle);
};
