const validateEmail = (value: string): string | undefined => {
  if (value) {
    /* eslint-disable no-control-regex */
    const regExp =
      /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;

    return regExp.test(value) ? undefined : 'Please use valid email address';
  }
  return undefined;
};

export default validateEmail;
