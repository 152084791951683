import { Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'ramda';
import { useDebounce, useUpdateEffect } from 'ahooks';
import CircularProgress from '@mui/material/CircularProgress';
import z from 'zod';
import Modal from 'src/ui/Modal';
import productFormSchema from 'src/features/lobby/components/ProductCreateModal/schema';
import { ProductAPI } from 'src/services/api/ProductAPI';
import { UserAPI } from 'src/services/api/UserAPI';
import { setMessage } from 'src/store/messages';
import { useAppDispatch } from 'src/store/useAppDispatch';

interface ProductCreateModalProps {
  open: boolean;
  onClose: () => void;
}

const ProductCreateModal: FC<ProductCreateModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { mutateAsync: create, isLoading: isCreateLoading, error: createError } = ProductAPI.useCreateProduct();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isDirty, isValid, errors },
  } = useForm({
    resolver: zodResolver(productFormSchema),
    defaultValues: {
      name: '',
      shortName: '',
      productContentUrl: undefined,
      productAdmin: undefined,
      visible: false,
    },
  });

  const productAdminFieldValue = watch('productAdmin');

  const productAdminEmail = useDebounce(
    z.string().email().safeParse(productAdminFieldValue).success ? productAdminFieldValue : '',
    { wait: 500 }
  );

  const {
    data: productAdminId,
    isLoading: isProductAdminLoading,
    error: productAdminError,
  } = UserAPI.useGetUserId({ email: String(productAdminEmail) }, { retry: false, enabled: !!productAdminEmail });

  const onSubmit = handleSubmit(async (formData) => {
    if (formData.productAdmin && !productAdminId) {
      setError('productAdmin', { message: 'Could not find this user' });
      return;
    }

    const hasValidationsErrors = !isValid || !isEmpty(errors);
    if (hasValidationsErrors) {
      return;
    }

    try {
      await create({
        name: formData.name,
        shortName: formData.shortName,
        productContentUrl: formData.productContentUrl ? formData.productContentUrl : undefined,
        productAdmins: productAdminId ? [productAdminId] : undefined,
        visible: formData.visible,
      });
      dispatch(setMessage({ severity: 'success', content: `${formData.name} created` }));
      onClose?.();
    } catch (e) {
      if (e instanceof Error && 'status' in e && e.status === 400) {
        setError('name', { message: 'There is already a product with this name' });
      }
    }
  });

  useUpdateEffect(() => {
    if (productAdminError) {
      setError('productAdmin', { message: 'Could not find this user' });
    } else {
      clearErrors('productAdmin');
    }
  }, [productAdminError]);

  return (
    <Modal
      closable={!isCreateLoading}
      message={
        createError
          ? {
              content: 'There was an error creating the product',
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={onClose}
      title="Create Product"
    >
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  autoFocus
                  label="Product name (between 3 to 39 characters)"
                  placeholder="Product name"
                  inputProps={{ maxLength: 39 }}
                  required
                  disabled={isCreateLoading}
                  error={!!errors.name}
                  helperText={errors.name ? String(errors.name.message) : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="shortName"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  autoFocus
                  label="Short Name (2 characters)"
                  placeholder="Short name"
                  inputProps={{ maxLength: 2 }}
                  required
                  disabled={isCreateLoading}
                  error={!!errors.shortName}
                  helperText={errors.shortName ? String(errors.shortName.message) : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="productContentUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  autoFocus
                  label="Product Content URL"
                  placeholder="Product content URL"
                  disabled={isCreateLoading}
                  error={!!errors.productContentUrl}
                  helperText={errors.productContentUrl ? String(errors.productContentUrl.message) : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="productAdmin"
              render={({ field }) => (
                <TextField
                  {...field}
                  data-testid="product-create-product-admin"
                  label="Product Admin (email)"
                  value={field.value ?? ''}
                  disabled={isProductAdminLoading || isCreateLoading}
                  error={!!errors.productAdmin}
                  helperText={errors.productAdmin ? String(errors.productAdmin.message) : undefined}
                  InputProps={{
                    endAdornment: isProductAdminLoading ? <CircularProgress color="inherit" size={20} /> : null,
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="visible"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value ?? false}
                      disabled={isCreateLoading}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Restricted to Internal users only"
                />
              )}
            />
          </Stack>
          <Modal.Actions>
            <Button disabled={isCreateLoading} variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || isProductAdminLoading}
              loading={isCreateLoading}
            >
              OK
            </LoadingButton>
          </Modal.Actions>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default ProductCreateModal;
