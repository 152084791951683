import { List, ListItemButton, ListItemText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { FC, useCallback } from 'react';
import { ProjectGroupBaseDTO } from '@h2know-how/identity-sdk';

interface GroupsStepProps {
  groups?: ProjectGroupBaseDTO[];
  selectedGroupIds: string[];
  onSelectedGroupIdsChange: (ids: string[]) => void;
}
export const GroupsStep: FC<GroupsStepProps> = ({ onSelectedGroupIdsChange, groups, selectedGroupIds }) => {
  const handleGroupClick = useCallback(
    (id: string): void => {
      const currentIndex = selectedGroupIds.indexOf(id);
      const newChecked = [...selectedGroupIds];
      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      onSelectedGroupIdsChange(newChecked);
    },
    [onSelectedGroupIdsChange, selectedGroupIds]
  );

  return (
    <Box sx={{ overflowY: 'auto', height: '200px' }}>
      <List>
        {groups?.map((item) => (
          <ListItemButton key={item.groupId} dense onClick={() => handleGroupClick(item.groupId)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  display: '-webkit-box',
                  overflowWrap: 'break-word',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                },
              }}
              primary={item.name}
            />
            <Checkbox checked={selectedGroupIds.includes(item.groupId)} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
