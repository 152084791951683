import { createAsyncThunk } from '@reduxjs/toolkit';

import ArgumentError from 'src/store/errors/ArgumentError';
import { CallbackPageSearchParam } from 'src/router';
import type { AppDispatch } from 'src/store/types';
import serializeApiError from '../../utils/serializeApiError';
import { AuthState, HandleCallbackResponsePayload } from '../types';
import authoriseWithHash from '../utils/authoriseWithHash';
import authoriseWithCode from '../utils/authoriseWithCode';
import { AUTH_SLICE_NAME } from '../sliceName';

const handleCallback = createAsyncThunk<
  HandleCallbackResponsePayload,
  { urlSearchParams: URLSearchParams; hash: string },
  { dispatch: AppDispatch; state: { auth: AuthState } }
>(
  `${AUTH_SLICE_NAME}/handleCallback`,
  async ({ hash, urlSearchParams }, { getState, signal }) => {
    // if there is a hash, we process it to refresh the token
    if (hash) {
      return authoriseWithHash(hash, getState().auth, signal);
    }

    // if we have a code in the search params, we process it
    const code = urlSearchParams.get(CallbackPageSearchParam.Code);
    if (code) {
      return authoriseWithCode(code, getState().auth, signal);
    }

    // if we have neither, we have an error
    throw new ArgumentError('the hash and urlSearchParams cannot both be empty');
  },
  { serializeError: serializeApiError }
);

export default handleCallback;
