import { SerializedError, miniSerializeError } from '@reduxjs/toolkit';

import { isRequestError } from 'src/utils/type-helpers';

const serializeApiError = (error: unknown): SerializedError => {
  const serialzedError = miniSerializeError(error);
  if (isRequestError(error)) {
    serialzedError.code = error.status.toString();
  }
  return serialzedError;
};

export default serializeApiError;
