import { Tab, TabProps } from '@mui/material';
import { FC } from 'react';

const NavLink: FC<TabProps> = (props) => {
  return (
    <Tab
      role={props.role ?? 'button'}
      sx={{
        px: 2,
        height: '100%',
        width: 'auto',
        minWidth: 'auto',
        opacity: 'unset',
        color: 'action.active',
        '&:hover': {
          '--hover-bg-color':
            'rgba(var(--moata-palette-action-activeChannel) / var(--moata-palette-action-hoverOpacity))',
          backgroundColor: 'var(--hover-bg-color)',
        },
        '&.active': {
          '--active-bg-color':
            'rgba(var(--moata-palette-primary-mainChannel) / var(--moata-palette-action-activatedOpacity))',
          color: 'primary.main',
          backgroundColor: 'var(--active-bg-color)',
        },
        '&.active:hover': {
          '--active-hover-bg-color':
            'rgba(var(--moata-palette-primary-mainChannel) / calc(var(--moata-palette-action-activatedOpacity) + var(--moata-palette-action-hoverOpacity)))',
          backgroundColor: 'var(--active-hover-bg-color)',
        },
        ...props.sx,
      }}
      {...props}
    />
  );
};

export default NavLink;
