import { Chip, IconButton, Stack, TextField } from '@mui/material';
import { Plus } from '@mott-macdonald/smi-react-ui-kit/icons';
import { ChangeEvent, FC, FormEvent, useCallback } from 'react';
import { isInternalEmail } from 'src/utils/isInternalEmail';
import { isEmailValueValid, MAX_EMAILS_PER_INVITATION, RESTRICTED_EMAIL_ERROR_MESSAGE } from './helpers';

interface UserEmailsStepProps {
  email: string;
  onChange: (email: string) => void;
  emailList: string[];
  onListUpdate: (list: string[]) => void;
  error?: string;
  onError: (error?: string) => void;
  isProjectInternal: boolean;
}

export const UserEmailsStep: FC<UserEmailsStepProps> = ({
  onListUpdate,
  emailList,
  email,
  onChange,
  error,
  onError,
  isProjectInternal,
}) => {
  const handleEmailDelete = useCallback(
    (deleteEmail: string): void => {
      onListUpdate(emailList.filter((emailAddress) => emailAddress !== deleteEmail));
    },
    [emailList, onListUpdate]
  );

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onChange(event.target.value);
      if (error) onError(undefined);
    },
    [error, onChange, onError]
  );

  const addNewEmail = useCallback(
    (e?: FormEvent): void => {
      e?.preventDefault();
      if (emailList.length === MAX_EMAILS_PER_INVITATION) {
        onError(`Sorry, maximum ${MAX_EMAILS_PER_INVITATION} emails per invitation allowed`);
        return;
      }
      if (isEmailValueValid(email)) {
        if (isProjectInternal && !isInternalEmail(email)) {
          onError(RESTRICTED_EMAIL_ERROR_MESSAGE);
        } else if (emailList.includes(email)) {
          onError('You already added this email');
        } else {
          onListUpdate([...emailList, email]);
          onChange('');
        }
      } else {
        onError('Please input a valid email address');
      }
    },
    [emailList, email, onError, onListUpdate, onChange, isProjectInternal]
  );

  return (
    <Stack display="flex" gap={2} width="100%">
      <form onSubmit={addNewEmail}>
        <Stack direction="row" alignItems="flex-start" gap={1}>
          <TextField
            autoFocus
            label="Email address"
            variant="outlined"
            fullWidth
            value={email}
            error={!!error}
            onChange={handleEmailChange}
            helperText={error || undefined}
          />
          <IconButton onClick={addNewEmail}>
            <Plus />
          </IconButton>
        </Stack>
      </form>
      <Stack
        direction="row"
        gap={1}
        sx={{ overflowY: 'auto', height: '145px', alignContent: 'flex-start', flexWrap: 'wrap' }}
      >
        {emailList?.map((emailAddress) => (
          <Chip key={emailAddress} size="small" label={emailAddress} onDelete={() => handleEmailDelete(emailAddress)} />
        ))}
      </Stack>
    </Stack>
  );
};
