import useCallbackRedirectAuthParams from 'src/features/auth/hooks/useCallbackRedirectParams';
import useAuth from 'src/hooks/useAuth';

const ParamPlaceHolder = {
  Token: '##TOKEN##',
  IdentityId: '##IDENTITYID##',
  ProjectName: '##PROJECTNAME##',
  ProductId: '##PRODUCTID##',
  ResourceName: '##RESOURCENAME##',
} as const;

interface UseProjectUrlReturn {
  getProjectUrl: () => Promise<string | undefined>;
}

const useProjectUrl = (
  sourceUrl: string | undefined,
  projectId?: number,
  projectName?: string | undefined,
  productId?: string | 0,
  resourceName?: string | undefined
): UseProjectUrlReturn => {
  const { getApiToken } = useAuth();
  const authParams = useCallbackRedirectAuthParams();

  const getProjectUrl = async (): Promise<string | undefined> => {
    if (!sourceUrl) return undefined;

    const token = await getApiToken();

    return sourceUrl
      .replace(ParamPlaceHolder.Token, `${token}&${new URLSearchParams(authParams).toString()}`)
      .replace(ParamPlaceHolder.IdentityId, projectId ? String(projectId) : ParamPlaceHolder.IdentityId)
      .replace(ParamPlaceHolder.ProjectName, projectName ?? ParamPlaceHolder.ProjectName)
      .replace(ParamPlaceHolder.ProductId, productId ? String(productId) : ParamPlaceHolder.ProductId)
      .replace(ParamPlaceHolder.ResourceName, resourceName ?? ParamPlaceHolder.ResourceName);
  };

  return { getProjectUrl };
};

export default useProjectUrl;
