import { CallbackRedirectAuthParam } from 'src/features/auth/constants';

export enum AppRouteSegment {
  Actions = 'actions',
  Admin = 'admin',
  Callback = 'callback',
  Confirmation = 'confirmation',
  GroupId = ':groupId',
  Groups = 'groups',
  Login = 'login',
  Logout = 'logout',
  MarketPlace = 'marketplace',
  Products = 'products',
  ProductId = ':productId',
  ProjectId = ':projectId',
  Projects = 'projects',
  Resources = 'resources',
  TermsAndConditions = 'terms-and-conditions',
  Root = '/',
  Update = 'update',
  User = 'user',
  UserId = ':userId',
  Users = 'users',
  Wildcard = '*',
  Workspace = 'workspace',
}

export enum AppPage {
  Home = 'home',
  MarketPlace = 'marketplace',
  ProjectConfiguration = 'project-configuration',
  ProjectConfigurationGroups = 'project-configuration-groups',
  ProjectConfigurationUsers = 'project-configuration-users',
  ProjectConfigurationActions = 'project-configuration-actions',
  ProjectGroupConfiguration = 'project-group-configuration',
  ProjectUserConfiguration = 'project-user-configuration',
  AdminProducts = 'admin-products',
  ProductConfiguration = 'product-configuration',
  ProductConfigurationGroups = 'product-configuration-groups',
  ProductConfigurationActions = 'product-configuration-actions',
  ProductGroupConfiguration = 'product-group-configuration',
  Login = 'login',
  Logout = 'logout',
  LogoutConfirmation = 'logout-confirmation',
  Callback = 'callback',
  UserUpdate = 'user-update',
  TermsAndConditions = 'terms-and-conditions',
}

export const AppFullRoutePath = {
  [AppPage.Home]: `/`,
  [AppPage.MarketPlace]: `/${AppRouteSegment.MarketPlace}`,
  [AppPage.ProjectConfiguration]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}`,
  [AppPage.ProjectConfigurationGroups]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}/${AppRouteSegment.Groups}`,
  [AppPage.ProjectConfigurationUsers]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}/${AppRouteSegment.Users}`,
  [AppPage.ProjectConfigurationActions]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}/${AppRouteSegment.Actions}`,
  [AppPage.ProjectGroupConfiguration]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}/${AppRouteSegment.Groups}/${AppRouteSegment.GroupId}`,
  [AppPage.ProjectUserConfiguration]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Projects}/${AppRouteSegment.ProjectId}/${AppRouteSegment.Users}/${AppRouteSegment.UserId}`,
  [AppPage.AdminProducts]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Products}`,
  [AppPage.ProductConfiguration]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Products}/${AppRouteSegment.ProjectId}`,
  [AppPage.ProductConfigurationGroups]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Products}/${AppRouteSegment.ProductId}/${AppRouteSegment.Groups}`,
  [AppPage.ProductConfigurationActions]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Products}/${AppRouteSegment.ProductId}/${AppRouteSegment.Actions}`,
  [AppPage.ProductGroupConfiguration]: `/${AppRouteSegment.Admin}/${AppRouteSegment.Products}/${AppRouteSegment.ProductId}/${AppRouteSegment.Groups}/${AppRouteSegment.GroupId}`,
  [AppPage.Login]: `/${AppRouteSegment.Login}`,
  [AppPage.Logout]: `/${AppRouteSegment.Logout}`,
  [AppPage.LogoutConfirmation]: `/${AppRouteSegment.Logout}/${AppRouteSegment.Confirmation}`,
  [AppPage.Callback]: `/${AppRouteSegment.Callback}`,
  [AppPage.UserUpdate]: `/${AppRouteSegment.User}/${AppRouteSegment.Update}`,
  [AppPage.TermsAndConditions]: `/${AppRouteSegment.TermsAndConditions}`,
} as const;

export const LoginPageSearchParam = {
  /**
   * @optional
   *
   * When exists, the login page will be bring this `redirect_uri` and all other params that are supported by Azure B2C login to the Azure B2C login authorization page.
   *
   * All supported params:
   * https://learn.microsoft.com/en-us/azure/active-directory-b2c/authorization-code-flow#1-get-an-authorization-code
   *
   * Explanation:
   *   (This `redirect_uri` param is refactored and moved here for an existing scenario.)
   *
   *   By default, we want the Identity app is acting as the SSO system for the entire Moata sub-apps hiding the Azure B2C login details behind the scene.
   *   So, the default `redirect_uri` is the callback page of this Identity app.
   *
   *   However, for some reasons, some sub-apps want to override the default behaviour.
   *   They want to talk to Azure B2C login directly and handle the Azure B2C login by themselves.
   *   So, they need to override the default `redirect_uri` to their own callback page.
   *
   *   We check the existence of this `redirect_uri` param, if it exists, we will bring it and all other supported params to the Azure B2C login authorization page.
   *   Then the Azure B2C login will redirect the user back to their `redirect_uri` page.
   *
   *   Sub-apps that are using this behaviour. (may be other unknown sub-apps as well.)
   *     - "Moata Inspect" mobile app
   */
  RedirectUri: 'redirect_uri',
  /**
   * @optional
   *
   * The redirect URI of the sub-app's callback page, where authentication responses are sent and received.
   *
   * When `callback_uri` is provided, once Identity app is authenticated from B2C, it will redirect to the `callback_uri` page with tokens and the `state`.
   *
   * (have to make a new params for this as the `redirect_uri` is occupied by the scenario above. Ref: {@link LoginPageSearchParam.RedirectUri})
   *
   */
  CallbackUri: 'callback_uri',
  /**
   * @optional
   *
   * Stores the relative path of the page that the user was on before being redirected to the login page.
   *
   * It could be a relative path of this Identity app, or a relative path of a Moata sub-app when `callback_uri` is provided.
   */
  State: 'state',
} as const;

export const CallbackPageSearchParam = {
  /**
   * @requires
   *
   * The authorization code that the Identity app received from Azure B2C login.
   *
   * Check out the "successful response" section of this page for more details:
   * https://learn.microsoft.com/en-us/azure/active-directory-b2c/authorization-code-flow#1-get-an-authorization-code
   */
  Code: 'code',
  /**
   * @optional
   *
   * The state that the Identity app received from Azure B2C login.
   *
   * It's the same `state` that Identity app login page sent to Azure B2C login.
   *
   * A JSON string that contains the values of the `callback_uri` and `state` params that the Identity app login page received.
   *
   * Refer to {@link B2cStatePayload} for the shape of the JSON.
   */
  State: 'state',
} as const;

export const CallbackPageHashParam = {
  Policy: CallbackRedirectAuthParam.Policy,
  RefreshToken: CallbackRedirectAuthParam.RefreshToken,
} as const;
