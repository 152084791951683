import z from 'zod';

const groupFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(4, 'Name must be at least 4 characters.')
    .max(50, 'Name must be less than 50 characters')
    .regex(/^[\w\s]+$/, 'Name must be alphanumeric.'),
  default: z.boolean(),
  adminGroup: z.boolean(),
  hidden: z.boolean(),
  nonEditable: z.boolean(),
  disabled: z.boolean(),
  resourceMapping: z.boolean(),
});

export default groupFormSchema;
