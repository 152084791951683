import { useEffect } from 'react';
import { getB2cTokens } from 'src/store/auth/selectors';
import { useAppSelector } from 'src/store/useAppSelector';
import { TOKEN_EXPIRY_THRESHOLD_IN_MS } from 'src/utils/constants';
import { getJwtExpiresAt } from 'src/utils/jwtHelpers';
import useAuth from 'src/hooks/useAuth';

const getRefreshDelay = (idToken: string): number => {
  const idTokenExpiry = getJwtExpiresAt(idToken);
  const delay = idTokenExpiry - TOKEN_EXPIRY_THRESHOLD_IN_MS - Date.now();
  return delay > 0 ? delay : 0;
};

export const useAutoRefreshTokens = (): void => {
  const { id_token: idToken } = useAppSelector(getB2cTokens) ?? {};
  const { refreshTokens } = useAuth();

  useEffect(() => {
    if (!idToken) return undefined;

    const refreshDelay = getRefreshDelay(idToken);

    const abortController = new AbortController();

    const timer = setTimeout(() => {
      refreshTokens(abortController.signal).catch(() => {
        // suspend error
        // slightly refresh
      });
    }, refreshDelay);

    return () => {
      abortController.abort();
      clearTimeout(timer);
    };
  }, [idToken, refreshTokens]);
};
