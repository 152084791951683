import { createUrlObject } from './createUrlObject';

export const mergeHashParamsToUrl = (url: string, params: Record<string, string>): string => {
  const newUrlObj = createUrlObject(url);
  if (!newUrlObj) return url;
  const hashParams = new URLSearchParams(newUrlObj.hash.slice(1));
  const mergedParams = new URLSearchParams({ ...Object.fromEntries(hashParams.entries()), ...params });
  newUrlObj.hash = `#${mergedParams.toString()}`;
  return newUrlObj.toString();
};
