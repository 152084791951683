import { FC } from 'react';
import { useBoolean } from 'react-use';
import { Button } from '@mui/material';
import { AdminItemType } from 'src/features/admin/types';
import { GroupManageActionsModal } from './GroupManageActionsModal';

interface ProjectGroupManageActionsButtonProps {
  itemType: AdminItemType;
  groupId: string;
  itemId: number;
  productId: number;
  actionIds?: string[];
}

export const GroupManageActionsButton: FC<ProjectGroupManageActionsButtonProps> = ({
  itemType,
  groupId,
  itemId,
  productId,
  actionIds,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useBoolean(false);

  return (
    <>
      <Button fullWidth onClick={toggleIsModalOpen}>
        Manage Actions
      </Button>
      {isModalOpen && (
        <GroupManageActionsModal
          itemType={itemType}
          itemId={itemId}
          groupId={groupId}
          productId={productId}
          actionIds={actionIds}
          open={isModalOpen}
          onClose={() => toggleIsModalOpen(false)}
        />
      )}
    </>
  );
};
