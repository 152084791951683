import { AppRouteSegment } from 'src/router/constants';
import { AdminItemType, AdminItemConfigurationPageTab } from 'src/features/admin/types';

const AdminTypesRoutes: Record<AdminItemType, AppRouteSegment> = {
  [AdminItemType.Project]: AppRouteSegment.Projects,
  [AdminItemType.Product]: AppRouteSegment.Products,
};

export const RouteBuilder = {
  admin: (itemType: AdminItemType, itemId: string | number): string =>
    `${AppRouteSegment.Admin}/${AdminTypesRoutes[itemType]}/${itemId}`,
  adminView: (itemType: AdminItemType, itemId: string | number, tab: AdminItemConfigurationPageTab): string =>
    `${AppRouteSegment.Admin}/${AdminTypesRoutes[itemType]}/${itemId}/${tab}`,
  adminGroup: (itemType: AdminItemType, itemId: string | number, groupId: string): string =>
    `${RouteBuilder.admin(itemType, itemId)}/${AppRouteSegment.Groups}/${groupId}`,
  projectAdminUser: (projectId: string | number, userId: string): string =>
    `${RouteBuilder.admin(AdminItemType.Project, projectId)}/${AppRouteSegment.Users}/${userId}`,
};
