import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'src/theme';
import Dialog, { DialogProps } from './Dialog';
import { addCloseFunction, removeCloseFunction } from './closeDialogFunctions';

// use thenable check to compatible with old version of promise (potentially from dependencies).
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isThenable<T>(thing?: any): thing is PromiseLike<T> {
  return typeof thing?.then === 'function';
}

export interface ActionHandlerArg {
  preventAutoClose: () => void;
  close: () => void;
  update: (updateProps: OpenDialogUpdateProps) => void;
}

type ActionHandler = (arg: ActionHandlerArg) => unknown;

type DialogPropsWithActionHandlers = Omit<DialogProps, 'onCancel' | 'onOk'> & {
  onCancel?: ActionHandler;
  onOk?: ActionHandler;
};

type OpenDialogProps = Omit<DialogPropsWithActionHandlers, 'open'>;

type TypedConfirmFunctionProps = Omit<OpenDialogProps, 'type'>;

type OpenDialogPropsForUpdate = Omit<OpenDialogProps, 'type' | 'onCancel' | 'onOk' | 'autoFocusButton'>;

type OpenDialogUpdateProps =
  | Partial<OpenDialogPropsForUpdate>
  | ((prevConfig: OpenDialogProps) => Partial<OpenDialogPropsForUpdate>);

export function openDialog(initialProps: OpenDialogProps): ActionHandlerArg {
  let isContainerUnmounted = false;
  const container = ReactDOM.createRoot(document.createDocumentFragment());

  let isAutoClose = true;

  const resetAutoClose = (): void => {
    isAutoClose = true;
  };
  const preventAutoClose = (): void => {
    isAutoClose = false;
  };

  const { onCancel, onOk, ...initialDialogProps } = initialProps;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const enhancedOnCancel = enhanceActionHandler(onCancel, setCancelButtonIsLoading);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const enhancedOnOk = enhanceActionHandler(onOk, setOkButtonIsLoading);

  let currentProps: DialogProps = {
    ...initialDialogProps,
    onCancel: enhancedOnCancel,
    onOk: enhancedOnOk,
    open: true,
  };

  let renderTimer: NodeJS.Timeout;

  function render(props: DialogProps): void {
    clearTimeout(renderTimer);
    renderTimer = setTimeout(() => {
      if (isContainerUnmounted) return;
      container.render(
        <ThemeProvider>
          <Dialog {...props} />
        </ThemeProvider>
      );
    });
  }

  function close(): void {
    isContainerUnmounted = true;
    removeCloseFunction(close);
    container.unmount();
  }

  function update(props: Partial<OpenDialogUpdateProps>): void {
    const newProps = typeof props === 'function' ? props(currentProps) : props;
    currentProps = {
      ...currentProps,
      ...newProps,
      cancelButtonProps: {
        ...currentProps.cancelButtonProps,
        ...newProps.cancelButtonProps,
      },
      okButtonProps: {
        ...currentProps.okButtonProps,
        ...newProps.okButtonProps,
      },
    };
    render(currentProps);
  }

  function setCancelButtonIsLoading(isLoading: boolean): void {
    update({ cancelButtonProps: { loading: isLoading } });
  }

  function setOkButtonIsLoading(isLoading: boolean): void {
    update({ okButtonProps: { loading: isLoading } });
  }

  function enhanceActionHandler(
    actionHandler?: ActionHandler,
    setIsLoading?: (isLoading: boolean) => void
  ): () => Promise<void> {
    return async () => {
      resetAutoClose();

      const returnValue = actionHandler?.({ preventAutoClose, close, update });

      if (!!setIsLoading && isThenable(returnValue)) {
        setIsLoading(true);

        try {
          await returnValue;
        } catch {
          // when the caller relies on the dialog to handle the error,
          // suppress error here to prevent uncaught error,
          // return here to prevent the dialog from closing.
          return;
        } finally {
          setIsLoading(false);
        }
      }

      if (isAutoClose) {
        close();
      }
    };
  }

  render(currentProps);

  addCloseFunction(close);

  return {
    preventAutoClose,
    close,
    update,
  };
}

export function openConfirmDialog(props: TypedConfirmFunctionProps): ActionHandlerArg {
  return openDialog({
    ...props,
    type: 'confirm',
  });
}
export function openAlertDialog(props: TypedConfirmFunctionProps): ActionHandlerArg {
  return openDialog({
    ...props,
    type: 'alert',
  });
}
