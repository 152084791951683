export const TermsAndConditionsFileNames = [
  {
    title: 'Data Protection Terms for Moata',
    description:
      'Document detailing the data protection policies and practices, ensuring the protection and proper use of personal information in compliance with relevant data protection laws.',
    filename: 'Data Protection Terms for Moata.pdf',
  },
  {
    title: 'Digital Solutions Privacy Notice',
    description:
      'The Digital Solutions Privacy Notice explains how your personal data is collected, used, and shared when using their services, ensuring transparency and compliance with international data protection laws.',
    filename: 'Digital Solutions Privacy Notice.pdf',
  },
  {
    title: 'General Terms and Conditions for Moata',
    description:
      'Provides an outline of the rules for using Moata products and services, covering orders, subscriptions, and consultancy services.',
    filename: 'General Terms and Conditions for Moata.pdf',
  },

  {
    title: 'Local Contract Terms for Moata',
    description:
      'Specifies additional or replacement conditions based on the country or state (USA) where the services are provided, ensuring compliance with local regulations.​',
    filename: 'Local Contract Terms for Moata.pdf',
  },
  {
    title: 'Moata Community Terms of Use',
    description:
      'The Moata Community Terms of Use by Mott MacDonald outlines the rules for accessing and using the Moata Community platform. It covers user responsibilities and acceptable use.',
    filename: 'Moata Community Terms of Use.pdf',
  },
  {
    title: 'Moata Consultancy Terms',
    description:
      'Provides the overall terms and conditions based on the country or state (USA) where the services are provided, ensuring compliance with local regulations.​',
    filename: 'Moata Consultancy Terms.pdf',
  },
  {
    title: 'Product Specific Terms for Moata Carbon Portal',
    description:
      'Product Specific terms outlining the specific conditions for using the Moata Carbon Portal, including its functionalities and user obligations.',
    filename: 'Product Specific Terms for Moata Carbon Portal.pdf',
  },
  {
    title: 'Product Specific Terms for Moata Geospatial',
    description:
      'Provides the specific terms for using Moata Geospatial, covering access, usage, and data protection related to geospatial products. This document should be read in conjunction with The Mota General Terms and Conditions Document.',
    filename: 'Product Specific Terms for Moata Geospatial.pdf',
  },
  {
    title: 'Product Specific Terms for Moata Intelligent Content',
    description:
      'The specific terms for using Moata intelligent Content, covering access, usage, and data protection related to geospatial products. This document should be read in conjunction with The Mota General Terms and Conditions Document.',
    filename: 'Product Specific Terms for Moata Intelligent Content.pdf',
  },
  {
    title: 'Product Specific Terms for Moata Land Management',
    description:
      'Outlines the specific terms for using Moata Land Management, including data storage, management, and user responsibilities. This document should be read in conjunction with The Mota General Terms and Conditions Document.',
    filename: 'Product Specific Terms for Moata Land Management.pdf',
  },
];
