import { TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { ProjectGroupBaseDTO } from '@h2know-how/identity-sdk';
import { renderPlaceholderTemplate } from 'src/utils/renderPlaceholderTemplate';

export const getDefaultInviteMessage = (
  selectedGroupIds: string[],
  inviteTemplate = '',
  groups?: ProjectGroupBaseDTO[],
  projectName = '',
  sender = ''
): string => {
  const groupNames =
    groups
      ?.filter((group) => selectedGroupIds.includes(group.groupId))
      .map((group) => group.name)
      ?.join(', ') ?? '';

  return renderPlaceholderTemplate(inviteTemplate, { projectName, groupNames, sender });
};

interface InvitationMessageStepProps {
  disabled?: boolean;
  value?: string;
  error?: string;
  onError: (error?: string) => void;
  onChange: (text: string) => void;
}

export const InvitationMessageStep: FC<InvitationMessageStepProps> = ({
  disabled = false,
  error,
  onError,
  value = '',
  onChange,
}) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
    onError(undefined);
  };

  return (
    <TextField
      disabled={disabled}
      label="Invitation Message"
      multiline
      rows={9}
      fullWidth
      value={value}
      onChange={handleOnChange}
      error={!!error}
      helperText={error}
    />
  );
};
