import { createContext, FC, useMemo, useCallback, PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import ProductCreateModal from '../ProductCreateModal';

export interface ProductCreateContextValue {
  openProductCreateModal: () => void;
}

const ProductCreateContext = createContext<ProductCreateContextValue>({
  openProductCreateModal: () => {},
});

const ProductCreateContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, toggleModal] = useToggle(false);

  const value = useMemo(
    () => ({
      openProductCreateModal: () => {
        toggleModal(true);
      },
    }),
    [toggleModal]
  );

  const closeProductCreate = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  return (
    <ProductCreateContext.Provider value={value}>
      {children}
      {isModalOpen && <ProductCreateModal open={isModalOpen} onClose={closeProductCreate} />}
    </ProductCreateContext.Provider>
  );
};

export { ProductCreateContext, ProductCreateContextProvider };
