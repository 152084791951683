import { FC } from 'react';
import { useCurrentProject } from 'src/features/admin/hooks/useCurrentProject';
import { AppPage, RouteBuilder } from 'src/router';
import { AdminItemType } from 'src/features/admin/types';
import { GenericGroupAdminPageLayout } from '../GenericAdminPageLayout';

export const ProjectGroupAdminPageLayout: FC = () => {
  const { data: currentProject } = useCurrentProject();

  return (
    <GenericGroupAdminPageLayout
      itemName={currentProject?.name}
      itemType={AdminItemType.Project}
      linkUrl={RouteBuilder.admin(AdminItemType.Project, currentProject?.projectId ?? '')}
      configPage={AppPage.ProjectConfiguration}
      groupConfigPage={AppPage.ProjectGroupConfiguration}
      userConfigPage={AppPage.ProjectUserConfiguration}
    />
  );
};
