import { FC, PropsWithChildren } from 'react';
import { StyledEngineProvider, Experimental_CssVarsProvider as CssVarsThemeProvider } from '@mui/material/styles';
import theme from './theme';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsThemeProvider
        theme={theme}
        // We only support light mode for this app.
        defaultMode="light"
        // only used to provide the theme context, do not generate CSS .
        disableStyleSheetGeneration
      >
        {children}
      </CssVarsThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
