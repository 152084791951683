import { useMemo } from 'react';
import { useAppSelector } from 'src/store/useAppSelector';
import { CallbackRedirectAuthParam } from '../constants';

type CallbackRedirectAuthParams = Record<
  (typeof CallbackRedirectAuthParam)[keyof typeof CallbackRedirectAuthParam],
  string
>;

const useCallbackRedirectAuthParams = (): CallbackRedirectAuthParams => {
  const auth = useAppSelector((state) => state.auth);

  return useMemo(
    () => ({
      [CallbackRedirectAuthParam.LoginUrl]: auth.projectLoginUrl ?? '',
      [CallbackRedirectAuthParam.LogoutUrl]: auth.projectLogoutUrl ?? '',
      [CallbackRedirectAuthParam.Policy]: auth.policy ?? '',
      [CallbackRedirectAuthParam.RefreshToken]: auth.b2cTokens?.refresh_token ?? '',
    }),
    [auth.b2cTokens?.refresh_token, auth.policy, auth.projectLoginUrl, auth.projectLogoutUrl]
  );
};

export default useCallbackRedirectAuthParams;
