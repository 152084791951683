import { Box, Typography } from '@mui/material';
import { ProjectMetadata } from '@h2know-how/identity-sdk';
import useContentQuery, { ContentValue } from 'src/services/api/useContentQuery';

export interface ProjectSummaryType {
  story: ContentValue;
}

// eslint-disable-next-line @rushstack/no-new-null
const useProjectSummary = (projectMetadata?: ProjectMetadata): JSX.Element | undefined | null => {
  const { projectSummaryUrl, projectSummary } = projectMetadata ?? {};
  const shouldRequireCmsProjectSummary = !!projectSummaryUrl;

  const { data: projectSummaryContent, isError: isProjectSummaryContentError } = useContentQuery<ProjectSummaryType>(
    projectSummaryUrl ?? '',
    {
      story: {
        path: 'sections[?(@.name=="Information")].fields[?(@[0].alias=="story")][0].value',
      },
    },
    {},
    { enabled: shouldRequireCmsProjectSummary }
  );

  // while projectMetadata is loading, return undefined
  if (projectMetadata === undefined) {
    return undefined;
  }

  const projectMetadataProjectSummary =
    projectSummary && projectSummary !== '' ? <Typography>{projectSummary}</Typography> : null;

  // if we don't have a project summary url, just return the project summary from the project metadata
  if (!shouldRequireCmsProjectSummary) {
    return projectMetadataProjectSummary;
  }

  // whilst CMS content is loading, return undefined
  if (projectSummaryContent === undefined && !isProjectSummaryContentError) {
    return undefined;
  }

  // on error, we just use the project summary from the project endpoint
  if (isProjectSummaryContentError) {
    return projectMetadataProjectSummary;
  }

  // we definitely have the summary content
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <Box>{projectSummaryContent!.story}</Box>;
};

export default useProjectSummary;
