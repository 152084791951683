import { ProjectDto } from '@h2know-how/identity-sdk';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isEmpty } from 'ramda';
import Modal from 'src/ui/Modal';
import useCreateProject from 'src/features/lobby/hooks/useCreateProject';
import useUpdateProject from 'src/features/lobby/hooks/useUpdateProject';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { setMessage } from 'src/store/messages';
import { isConflictErrorGuard } from 'src/services/api/helpers';
import {
  PROJECT_REGIONS,
  ProjectUsageTypes,
  TermsAndConditionsName,
  TermsAndConditionsType,
} from 'src/features/lobby/components/ProjectEditModal/constants';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { isInternalEmail } from 'src/utils/isInternalEmail';
import { UserAPI } from 'src/services/api/UserAPI';
import { useAppSelector } from 'src/store/useAppSelector';
import { getLocalCmsUrl } from 'src/utils/localCMS';
import ProductDropdown from './ProductDropdown';
import { getDefaultValues } from './helpers/getDefaultValues';
import { isAfterToday } from './helpers/isAfterToday';
import { isCreateProjectDto } from './helpers/isCreateProjectDto';
import projectFormSchema from './schema';

const tsAndCs = Object.entries(TermsAndConditionsType).map(([key, value]) => ({
  name: TermsAndConditionsName[key as keyof typeof TermsAndConditionsType],
  url: getLocalCmsUrl(`/tcs/${value}.json`),
  disabled: false,
}));

interface ProjectEditModalProps {
  open: boolean;
  onClose: () => void;
  initialProject?: ProjectDto;
  initialProductId?: number;
}

const ProjectEditModal: FC<ProjectEditModalProps> = ({ open, onClose, initialProject, initialProductId }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user) ?? undefined;

  const isUserInternal = useMemo(() => !!user && isInternalEmail(user.email), [user]);

  const defaultValues = getDefaultValues(initialProject, initialProductId);
  const {
    watch,
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty, isValid, errors },
  } = useForm({
    resolver: zodResolver(projectFormSchema),
    defaultValues,
  });

  const selectedProductId = watch('productId');
  const selectedUsageType = watch('usageType');

  const { data: projectUsers, isLoading: isProjectUsersLoading } = ProjectUserAPI.useGetByProjectId(
    Number(initialProject?.projectId),
    { enabled: !!initialProject }
  );

  const {
    data: productsOriginal,
    isLoading: isProductsLoading,
    error: productsError,
  } = UserAPI.useGetProductsWithAccess();

  const products = useMemo(
    () =>
      productsOriginal
        ?.filter((product) => (isUserInternal || !product.isInternal) && product.canCreateProjects)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [isUserInternal, productsOriginal]
  );

  const termsAndConditionsOptions = useMemo(() => {
    if (
      initialProject?.projectTermsAndConditionsUrl &&
      !tsAndCs.find((item) => item.url === initialProject.projectTermsAndConditionsUrl)
    ) {
      return [
        {
          name: initialProject.projectTermsAndConditionsUrl,
          url: initialProject.projectTermsAndConditionsUrl,
          disabled: true,
        },
        ...tsAndCs,
      ];
    }

    return tsAndCs;
  }, [initialProject]);

  const { mutateAsync: create, isLoading: isCreateLoading, error: createError } = useCreateProject();
  const { mutateAsync: update, isLoading: isUpdateLoading, error: updateError } = useUpdateProject();

  const isMutationLoading = isCreateLoading || isUpdateLoading;
  const mutationError = createError ?? updateError;
  const isConflictError = isConflictErrorGuard(mutationError);

  const hasExternalEmailsInInitialProject = useMemo(
    () => !!projectUsers?.users?.some((usr) => !isInternalEmail(usr.email)),
    [projectUsers]
  );

  const isSelectedProductInternal = useMemo(
    () => !!products?.some((product) => product.productId === selectedProductId && product.isInternal),
    [products, selectedProductId]
  );

  useEffect(() => {
    if (isSelectedProductInternal) {
      setValue('usageType', ProjectUsageTypes.Internal);
    }
  }, [isSelectedProductInternal, setValue]);

  const isSelectedUsageTypeExternal = selectedUsageType === ProjectUsageTypes.External;

  const onSubmit = handleSubmit(async (formData) => {
    const hasValidationsErrors = !isValid || !isEmpty(errors);
    if (hasValidationsErrors) return;

    try {
      if (initialProject) {
        await update({
          projectId: initialProject.projectId,
          data: { ...initialProject, ...formData },
        });
        dispatch(setMessage({ severity: 'success', content: `${formData.name} updated` }));
      } else if (isCreateProjectDto(formData)) {
        await create(formData);
        dispatch(setMessage({ severity: 'success', content: `${formData.name} created` }));
      }

      onClose();
    } catch (e) {
      if (e instanceof Error && 'status' in e && e.status === 409) {
        setError('name', { message: 'There is already a project with this name' });
      }
    }
  });

  return (
    <Modal
      closable={!isMutationLoading}
      message={
        mutationError && !isConflictError
          ? {
              content: 'There was an error updating the project',
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={onClose}
      title={initialProject ? 'Edit Project' : 'New Project'}
    >
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  autoFocus
                  label="Project name (between 3 to 39 characters)"
                  placeholder="Project name"
                  inputProps={{ maxLength: 39 }}
                  required
                  disabled={isMutationLoading}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="productId"
              render={({ field }) => (
                <ProductDropdown
                  field={field}
                  disabled={isMutationLoading || isProductsLoading || !!productsError || !!defaultValues.productId}
                  products={products}
                  isLoading={isProductsLoading}
                  error={productsError || undefined}
                  defaultProductId={defaultValues.productId}
                  productIdError={errors?.productId}
                />
              )}
            />

            <Controller
              control={control}
              name="subHeader"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label="Project subtitle"
                  disabled={isMutationLoading}
                  error={!!errors.subHeader}
                  helperText={errors.subHeader ? errors.subHeader.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="usageType"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  disabled={
                    isMutationLoading || isProductsLoading || isProjectUsersLoading || isSelectedProductInternal
                  }
                  select
                  label="Usage Type"
                  value={field.value ?? ''}
                  error={!!errors.usageType}
                  helperText={errors.usageType ? errors.usageType.message : undefined}
                >
                  {Object.values(ProjectUsageTypes)
                    .filter((usageType) =>
                      // only show Internal usage type when the current user is internal;
                      // only show non-Internal usage types when the selected product is not internal;
                      usageType === ProjectUsageTypes.Internal ? isUserInternal : !isSelectedProductInternal
                    )
                    .map((usageType) => (
                      <MenuItem
                        disabled={usageType === ProjectUsageTypes.Internal && hasExternalEmailsInInitialProject}
                        key={usageType}
                        value={usageType}
                      >
                        {usageType}
                      </MenuItem>
                    )) ?? <span />}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="contractId"
              render={({ field }) => (
                <TextField
                  {...field}
                  required={isSelectedUsageTypeExternal}
                  value={field.value ?? ''}
                  label="Contract Id"
                  disabled={isMutationLoading}
                  error={!!errors.contractId}
                  helperText={errors.contractId ? errors.contractId.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="validTill"
              disabled={isMutationLoading}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker<dayjs.Dayjs>
                    {...field}
                    // normally we use empty string (`''`) as the fallback value to mark the component as controlled.
                    // but the `DatePicker` component from MUI requires a `dayjs` object as value instead of a string,
                    // so we use a `null` here to mark this component as controlled.
                    value={field.value ? dayjs(field.value) : null}
                    label="Valid Until"
                    format="DD/MM/YYYY"
                    shouldDisableDate={(date) => !!date && !isAfterToday(date)}
                    onChange={(date) => field.onChange(date?.toISOString())}
                    onError={(error) =>
                      error
                        ? setError('validTill', { message: 'Valid Until date must be after today' })
                        : clearErrors('validTill')
                    }
                    slotProps={{
                      textField: {
                        error: !!errors.validTill,
                        helperText: errors.validTill ? errors.validTill.message : '',
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              control={control}
              name="region"
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={isMutationLoading}
                  select
                  required
                  label="Region"
                  value={field.value ?? ''}
                  error={!!errors.region}
                  helperText={errors.region ? errors.region.message : undefined}
                >
                  {PROJECT_REGIONS?.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  )) ?? <span />}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="imageUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label="Image URL"
                  disabled={isMutationLoading}
                  error={!!errors.imageUrl}
                  helperText={errors.imageUrl ? errors.imageUrl.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="projectSource"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label="Project source"
                  disabled={isMutationLoading}
                  error={!!errors.projectSource}
                  helperText={errors.projectSource ? errors.projectSource.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="projectSummaryUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label="Project summary URL"
                  disabled={isMutationLoading}
                  error={!!errors.projectSummaryUrl}
                  helperText={errors.projectSummaryUrl ? errors.projectSummaryUrl.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="newsListUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field.value ?? ''}
                  label="News Item URL"
                  disabled={isMutationLoading}
                  error={!!errors.newsListUrl}
                  helperText={errors.newsListUrl ? errors.newsListUrl.message : undefined}
                />
              )}
            />

            <Controller
              control={control}
              name="projectTermsAndConditionsUrl"
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={isMutationLoading}
                  select
                  required
                  label="T&Cs"
                  value={field.value ?? ''}
                  error={!!errors.projectTermsAndConditionsUrl}
                  helperText={
                    errors.projectTermsAndConditionsUrl ? errors.projectTermsAndConditionsUrl.message : undefined
                  }
                >
                  {termsAndConditionsOptions.map((tcs) => (
                    <MenuItem key={tcs.name} value={tcs.url} disabled={tcs.disabled}>
                      {tcs.name}
                    </MenuItem>
                  )) ?? <span />}
                </TextField>
              )}
            />
          </Stack>
          <Modal.Actions>
            <Button disabled={isMutationLoading} variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || isProductsLoading}
              loading={isMutationLoading}
            >
              OK
            </LoadingButton>
          </Modal.Actions>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default ProjectEditModal;
