import { useState, UIEvent, FC, PropsWithChildren } from 'react';
import { Dialog, Button, DialogActions, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import Skeleton from 'react-loading-skeleton';
import { LoadingButton as AgreeButton } from '@mui/lab';

import { clearTermsAndConditionsProps } from 'src/store/termsAndConditions';
import useContentQuery from 'src/services/api/useContentQuery';
import useGetScreenSize from 'src/hooks/useGetScreenSize';

import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAppSelector } from 'src/store/useAppSelector';
import { TermsAndConditionsData } from './types';
import TermsAndConditionsContent from './TermsAndConditionsContent';

const SkeletonWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Typography paragraph sx={{ display: 'flex' }}>
    {children}
  </Typography>
);

const TermsAndConditions: FC = () => {
  const dispatch = useAppDispatch();

  const { isXs } = useGetScreenSize();

  const termsAndConditions = useAppSelector((state) => state.termsAndConditions.props);

  const [isFullyRead, setIsFullyRead] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { data, isLoading, isError } = useContentQuery<TermsAndConditionsData>(
    termsAndConditions?.termsAndConditionsUrl ? termsAndConditions.termsAndConditionsUrl : '',
    {
      terms: { path: 'sections[1].fields[2][0].value' },
    },
    {},
    { enabled: !!termsAndConditions?.termsAndConditionsUrl }
  );

  const scrollHandler = ({ currentTarget: target }: UIEvent<HTMLDivElement>): void => {
    if (target.clientHeight + 5 >= target.scrollHeight - target.scrollTop) {
      setIsFullyRead(true);
    }
  };

  const acceptHandler = async (): Promise<void> => {
    if (termsAndConditions?.onAccept) {
      setIsSaving(true);

      try {
        await termsAndConditions.onAccept();

        setIsFullyRead(false);
        dispatch(clearTermsAndConditionsProps());
      } catch {
        // Just catch the error so it doesnt go to the console. Nothing more to do here
      } finally {
        setIsSaving(false);
      }
    }
  };

  const closeHandler = (): void => {
    setIsFullyRead(false);
    setIsSaving(false);
    dispatch(clearTermsAndConditionsProps());
  };

  return (
    <Dialog
      open={!!termsAndConditions}
      onClose={closeHandler}
      aria-labelledby="terms-and-conditions-title"
      aria-describedby="terms-and-conditions-description"
      maxWidth="sm"
      fullScreen={isXs}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="terms-and-conditions-title"
      >
        {termsAndConditions?.title ?? 'Terms and conditions'}
        <IconButton edge="end" size="small" aria-label="info" onClick={closeHandler} data-testid="close-terms-icon">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ maxHeight: isXs ? 'none' : 488 }}
        onScroll={termsAndConditions ? scrollHandler : undefined}
        data-testid="terms-text-content"
      >
        {data ? (
          <TermsAndConditionsContent content={data} isLoading={isLoading} isError={isError} />
        ) : (
          <Skeleton count={10} width={isXs ? undefined : 568} enableAnimation wrapper={SkeletonWrapper} />
        )}
      </DialogContent>
      <DialogActions>
        {termsAndConditions?.onAccept ? (
          <>
            <Button
              variant="outlined"
              data-testid="disagree-button"
              color="inherit"
              onClick={closeHandler}
              disabled={isSaving}
            >
              Disagree
            </Button>
            <AgreeButton
              loading={isSaving}
              variant="contained"
              data-testid="agree-button"
              color="primary"
              onClick={acceptHandler}
              disabled={isLoading || !isFullyRead}
              autoFocus
            >
              Agree
            </AgreeButton>
          </>
        ) : (
          <Button variant="contained" data-testid="close-button" color="primary" onClick={closeHandler} autoFocus>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditions;
