import { createSlice } from '@reduxjs/toolkit';
import { TermsAndConditionsState, TermsAndConditionsProps } from './types';

export const initialState: TermsAndConditionsState = {
  props: undefined,
};

const slice = createSlice({
  name: 'identity/termsAndConditions',
  initialState,
  reducers: {
    setTermsAndConditionsProps: (state, { payload: termsAndConditionsProps }: { payload: TermsAndConditionsProps }) => {
      state.props = termsAndConditionsProps;
    },
    clearTermsAndConditionsProps: (state) => {
      state.props = undefined;
    },
  },
});

export const { setTermsAndConditionsProps, clearTermsAndConditionsProps } = slice.actions;

export const { reducer } = slice;
