import TagManager from 'react-gtm-module';
import type { DataLayerEventArgs, DataLayerPayload, DataLayerVariablesArgs } from './types';

/**
 * Tracking events.
 */
export const trackGTMEvent = <Payload extends DataLayerPayload | void = void>(
  dataLayer: DataLayerEventArgs<Payload>
): void => {
  if (dataLayer) {
    TagManager.dataLayer(dataLayer);
  }
};

/**
 * Persisting variables.
 *
 * Having a separate function is mainly for the purpose of code readability,
 * because persisting variables has to be called before initializing the GTM.
 *
 * ref: https://developers.google.com/tag-platform/devguides/datalayer#persist_data_layer_variables
 */
export const trackGTMVariables = <Payload extends DataLayerPayload | void = void>(
  dataLayer: DataLayerVariablesArgs<Payload>
): void => {
  if (dataLayer) {
    TagManager.dataLayer(dataLayer);
  }
};
