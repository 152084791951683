import { Breadcrumbs, Stack } from '@mui/material';
import { FC, useState } from 'react';
import Loader from 'src/ui/Loader';
import { FetchErrorResult } from 'src/ui/Result';
import Search from 'src/ui/Search';
import { useCurrentProject } from 'src/features/admin/hooks/useCurrentProject';
import { useCurrentProjectId } from 'src/features/admin/hooks/useCurrentProjectId';
import { useCurrentProjectUserId } from 'src/features/admin/hooks/useCurrentProjectUserId';
import { useCurrentProjectUser } from 'src/features/admin/hooks/useCurrentProjectUser';
import { ProjectUserGroupsTable } from 'src/features/admin/components/ProjectUserGroupsTable';
import { ProjectUserConfigurationHeader } from 'src/features/admin/components/ProjectUserConfigurationHeader';

export const ProjectUserConfigurationPage: FC = () => {
  const projectUserId = useCurrentProjectUserId();
  const projectId = useCurrentProjectId();

  const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useCurrentProject();
  const { data: user, isLoading: isUserLoading, isError: isUserError } = useCurrentProjectUser();

  const [filterValue, setFilterValue] = useState('');

  if (!projectId || !projectUserId || isProjectError || isUserError) {
    return <FetchErrorResult />;
  }

  if (isProjectLoading || isUserLoading) return <Loader flex={1} />;

  // If there is no error and loading is done, we will have a project and user. So this acts as a type guard.
  if (!project || !user) return null;

  return (
    <>
      <Breadcrumbs />
      <ProjectUserConfigurationHeader projectId={Number(projectId)} user={user} />
      <Stack padding={2}>
        <Search
          label="Search Groups"
          name="searchGroups"
          filterValue={filterValue}
          onFilterValueChange={setFilterValue}
        />
      </Stack>
      <ProjectUserGroupsTable
        userGroupIds={user?.groups}
        projectId={Number(projectId)}
        userId={projectUserId}
        filterValue={filterValue}
      />
    </>
  );
};
