import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import useAuth from 'src/hooks/useAuth';
import { QueryResult } from 'src/services/api/types';

import ContentClient from './ContentClient';
import { ContentOptions } from './ContentClient/types';
import parseData, { Paths } from './parseData/parseData';

/**
 * Abstracted so it can be tested separately
 */
export const getQueryKeys = (contentURL: string, contentOptions?: ContentOptions): QueryKey => [
  contentURL,
  contentOptions,
];

/**
 * Use this when interacting with the content API.
 */
const useContentQuery = <T>(
  contentURL: string,
  /**
   * These are the paths of the values on the success object we want to parse and eventually render in the UI
   */
  pathsOnReturnedObj: Paths,
  contentOptions?: ContentOptions,
  queryOptions?: UseQueryOptions<unknown, unknown, unknown>
): QueryResult<T> => {
  const { getApiToken } = useAuth();
  const queryKeys = getQueryKeys(contentURL, contentOptions);

  return useQuery(
    queryKeys,
    async ({ signal }) => new ContentClient(contentURL, await getApiToken(), contentOptions).fetchContent({ signal }),
    {
      select: (data) => parseData(data, pathsOnReturnedObj),
      ...queryOptions,
    }
  ) as QueryResult<T>;
};

export default useContentQuery;
