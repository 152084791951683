import { SerializedError } from '@reduxjs/toolkit';
import { ProjectUserSimpleDTO } from '@h2know-how/identity-sdk';
import { AdminItemType } from 'src/features/admin/types';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { ProductGroupAPI } from 'src/services/api/ProductGroupAPI';

export const useGetUsersByGroupId = (
  type: AdminItemType,
  itemId: number,
  groupId: string
): { users?: ProjectUserSimpleDTO[]; isLoading: boolean; error?: SerializedError } => {
  const {
    data: projectUsers,
    isLoading: isProjectGroupUsersLoading,
    error: projectGroupUsersError,
  } = ProjectUserAPI.useGetByGroupId(itemId, groupId, { enabled: type === AdminItemType.Project });
  const {
    data: productUsers,
    isLoading: isProductGroupUsersLoading,
    error: productGroupUsersError,
  } = ProductGroupAPI.useGetUsers(itemId, groupId, { enabled: type === AdminItemType.Product });

  return {
    users: type === AdminItemType.Project ? projectUsers : productUsers,
    isLoading: isProjectGroupUsersLoading || isProductGroupUsersLoading,
    error: projectGroupUsersError || productGroupUsersError || undefined,
  };
};
