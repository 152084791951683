import { Action } from '@h2know-how/identity-sdk';
import { useCallback, useMemo } from 'react';
import { AdminItemType } from 'src/features/admin/types';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { ProductActionAPI } from 'src/services/api/ProductActionAPI';

export const useUpdateGroupActions = (
  itemType: AdminItemType,
  itemId: number,
  groupId: string,
  oldActionIds?: string[],
  allActions?: Action[]
): { update: (newActionIds: string[]) => Promise<void>; isLoading: boolean; isError: boolean } => {
  const allActionIds = useMemo(() => allActions?.map((action) => action.actionId) ?? [], [allActions]);

  const {
    mutateAsync: putActions,
    isLoading: isLoadingPutActions,
    isError: isErrorPutActions,
  } = ProjectGroupAPI.usePutActions();

  const {
    mutateAsync: addToGroup,
    isLoading: isLoadingAddToGroup,
    isError: isErrorAddToGroup,
  } = ProductActionAPI.useAddToGroup();

  const {
    mutateAsync: removeFromGroup,
    isLoading: isLoadingRemoveFromGroup,
    isError: isErrorRemoveFromGroup,
  } = ProductActionAPI.useRemoveFromGroup();

  const update = useCallback(
    async (newActionIds: string[]): Promise<void> => {
      if (itemType === AdminItemType.Project) {
        await putActions({ projectId: itemId, groupId, actionIds: [...newActionIds.values()] });
      } else {
        const updates: Promise<void>[] = [];
        const detachIds = [...allActionIds];
        newActionIds.forEach((actionId) => {
          if (!oldActionIds?.includes(actionId)) {
            updates.push(addToGroup({ productId: itemId, groupId, actionId }));
          }
          const detachIdIndex = detachIds.indexOf(actionId);
          if (detachIdIndex > -1) {
            detachIds.splice(detachIdIndex, 1);
          }
        });
        detachIds.forEach((actionId) => {
          updates.push(removeFromGroup({ productId: itemId, groupId, actionId }));
        });
        await Promise.all(updates);
      }
    },
    [allActionIds, addToGroup, removeFromGroup, groupId, itemId, itemType, oldActionIds, putActions]
  );

  return {
    update,
    isLoading: isLoadingPutActions || isLoadingAddToGroup || isLoadingRemoveFromGroup,
    isError: isErrorPutActions || isErrorAddToGroup || isErrorRemoveFromGroup,
  };
};
