import * as Sentry from '@sentry/react';

export const sendRedirectUriUsageEvent = ({
  landingUrl,
  referrerUrl,
}: {
  landingUrl: string;
  referrerUrl: string;
}): void => {
  Sentry.captureMessage('RedirectUri', {
    level: 'log',
    extra: {
      landingUrl,
      referrerUrl,
    },
  });
};
