import { equals } from 'ramda';
import TagManager from 'react-gtm-module';

const MM_EMAIL_DOMAIN = 'mottmac.com';

export const setGTMDataLayerVariables = (user: { id: string; email: string }): void => {
  const userDomain = user.email.split('@')[1];
  const isMM = equals(userDomain, MM_EMAIL_DOMAIN).toString();

  TagManager.dataLayer({ dataLayer: { user: { isMM, userDomain, id: user.id } } });
};
