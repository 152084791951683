import { FC } from 'react';
import { Box, BoxProps, CircularProgress, CircularProgressProps, styled } from '@mui/material';

const Container = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Loader: FC<BoxProps & { size?: CircularProgressProps['size'] }> = ({ size, ...props }) => {
  return (
    <Container {...props}>
      <CircularProgress size={size} />
    </Container>
  );
};

export default Loader;
