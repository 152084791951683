import { FC, Fragment } from 'react';
import {
  IconButton,
  Link,
  Dialog,
  DialogTitle,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon, Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';

import { HELPER_LINKS } from 'src/features/lobby/components/Header/HelperMenu/helperLinks';
import { HELP_BAR_ITEM_NAME } from '../constants';

const XsHelperMenu: FC<{
  // eslint-disable-next-line @rushstack/no-new-null
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onItemClick: ({ name }: { name: string }) => void;
  onShowChameleonHelpBarClick: ({ name }: { name: string }) => void;
}> = ({ anchorElement, onClose, onItemClick, onShowChameleonHelpBarClick }) => {
  return (
    <Dialog
      open={!!anchorElement}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        id="dialog-title"
      >
        Help
        <IconButton edge="end" size="small" aria-label="Close" onClick={onClose} title="Close">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <List component="div" disablePadding>
        {HELPER_LINKS.map(({ label, link, id }) => (
          <Fragment key={`${label}-${link}`}>
            <ListItemButton
              onClick={() => {
                onItemClick({ name: label });
              }}
              component={Link}
              href={link}
              data-testid={id}
              target="_blank"
            >
              <ListItemText primary={label} />
              <ListItemIcon sx={{ minWidth: 'auto' }}>
                <ArrowRightIcon fontSize="small" />
              </ListItemIcon>
            </ListItemButton>
            <Divider />
          </Fragment>
        ))}
        <>
          <ListItemButton
            onClick={() => {
              onShowChameleonHelpBarClick({ name: HELP_BAR_ITEM_NAME });
            }}
          >
            <ListItemText primary={HELP_BAR_ITEM_NAME} />
          </ListItemButton>
          <Divider />
        </>
      </List>
    </Dialog>
  );
};

export default XsHelperMenu;
