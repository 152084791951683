import { FC, forwardRef } from 'react';
import { Snackbar, Alert as MuiAlert, AlertProps, AlertTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mott-macdonald/smi-react-ui-kit/icons';
import { clearMessage } from 'src/store/messages';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAppSelector } from 'src/store/useAppSelector';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageSnackbar: FC = () => {
  const dispatch = useAppDispatch();

  const message = useAppSelector((state) => state.messages.message);

  return (
    <Snackbar transitionDuration={0} open={!!message} autoHideDuration={6000} onClose={() => dispatch(clearMessage())}>
      <Alert
        data-testid="snackbar-alert"
        action={
          <IconButton
            onClick={() => dispatch(clearMessage())}
            color="inherit"
            size="small"
            data-testid="snackbar-alert-close-button"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={message?.severity}
        sx={{ width: '100%' }}
      >
        {message?.title && <AlertTitle>{message.title}</AlertTitle>}
        {message?.content}
      </Alert>
    </Snackbar>
  );
};

export default MessageSnackbar;
