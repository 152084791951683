import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from './auth';
import { AUTH_SLICE_NAME } from './auth/sliceName';
import { reducer as messagesReducer } from './messages';
import { reducer as termsAndConditionsReducer } from './termsAndConditions';
import { reducer as detailPanelReducer } from './detailPanel';
import { reducer as loadingReducer } from './loading';

const rootReducer = combineReducers({
  [AUTH_SLICE_NAME]: authReducer,
  messages: messagesReducer,
  termsAndConditions: termsAndConditionsReducer,
  detailPanel: detailPanelReducer,
  loading: loadingReducer,
});

export default rootReducer;
