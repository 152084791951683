import { ActionType, ProjectUserSimpleDTO } from '@h2know-how/identity-sdk';
import dayjs from 'dayjs';
import { DATE_FORMAT_DDMMYYYY } from 'src/utils/constants';
import { ReservedProductActionNames, ReservedProjectActionNames } from 'src/features/admin/constants';

// Users do not have a unique value. userID can be zeroes when they are in the pending state
// and there is no unique constraint on other values.
// To limit the potential for duplicate keys within a list of users, use a combination of potentially unique values.
export const makeUserUIKey = (user: ProjectUserSimpleDTO): string =>
  `${user.userId}${user.displayName}${user.email}${user.givenName}${user.lastActive}`;

export const formatLastActiveDate = (user: ProjectUserSimpleDTO): string => {
  return user.userState !== 'PENDING' ? dayjs(user?.lastActive)?.format(DATE_FORMAT_DDMMYYYY) : 'Not active yet';
};

export const isActionNameReserved = (actionType: ActionType.Project | ActionType.Product, name?: string): boolean => {
  if (actionType === ActionType.Product) {
    return ReservedProductActionNames.includes(name ?? '');
  }

  return ReservedProjectActionNames.includes(name ?? '');
};
