import { useMemo } from 'react';
import { useAppSelector } from 'src/store/useAppSelector';
import { decodeIdToken, IdTokenPayload } from 'src/utils/jwtHelpers';

const useIdTokenPayload = (): IdTokenPayload | undefined => {
  const idToken = useAppSelector(({ auth }) => auth.b2cTokens)?.id_token;

  const payload = useMemo(() => (idToken ? decodeIdToken(idToken) : undefined), [idToken]);

  return payload;
};

export default useIdTokenPayload;
