export interface B2cStatePayload {
  callbackUri?: string;
  state?: string;
}

export const encodeB2cStatePayload = (payload: B2cStatePayload | undefined): string | undefined =>
  payload ? btoa(JSON.stringify(payload)) : undefined;

export const decodeB2cStatePayload = (state: string | undefined): B2cStatePayload | undefined => {
  if (!state) return undefined;
  try {
    return JSON.parse(atob(state)) ?? undefined;
  } catch {
    return undefined;
  }
};
