export const toRelativeLocationString = ({
  pathname = '',
  hash = '',
  search = '',
}: {
  /**
   * A URL pathname, beginning with a /.
   */
  pathname?: string;
  /**
   * A URL search string, beginning with a ?.
   */
  search?: string;
  /**
   * A URL fragment identifier, beginning with a #.
   */
  hash?: string;
} = {}): string => [pathname, search, hash].join('');
