import { FC, useEffect } from 'react';
import { Avatar, Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { ProductMetadata } from '@h2know-how/identity-sdk';

import LoaderTile from 'src/ui/LoaderTile';
import useContentQuery from 'src/services/api/useContentQuery';

import { getLocalCmsUrl } from 'src/utils/localCMS';

import ActionButton from './ActionButton';
import { ProductData } from './types';

interface Props {
  productId: ProductMetadata['productId'];
  productContentUrl: ProductMetadata['productContentUrl'];
  shortName: ProductMetadata['shortName'];
  productName: ProductMetadata['name'];
  onLoaded: () => void;
}

const ProductTile: FC<Props> = ({ productId, shortName, onLoaded, productName }: Props) => {
  const { data, isLoading, isError } = useContentQuery<ProductData>(getLocalCmsUrl(`/products/${productId}.json`), {
    name: { path: 'name' },
    description: { path: 'sections[1].fields[1][0].value' },
    imageUrl: { path: 'sections[1].fields[5][0].value' },
    videoUrl: { path: 'sections[1].fields[9][0].value' },
    linkOneLabel: { path: 'sections[1].fields[10][0].value' },
    linkOneType: { path: 'sections[1].fields[11][0].value' },
    linkOneLink: { path: 'sections[1].fields[12][0].value' },
    linkTwoLabel: { path: 'sections[1].fields[13][0].value' },
    linkTwoType: { path: 'sections[1].fields[14][0].value' },
    linkTwoLink: { path: 'sections[1].fields[15][0].value' },
    ownersEmails: { path: 'sections[1].fields[18][0].value' },
    owner: { path: 'sections[1].fields[20][0].value' },
    externalWebsiteURL: { path: 'sections[1].fields[21][0].value' },
  });

  useEffect(() => {
    if (data !== undefined) {
      onLoaded();
    }
  }, [data, onLoaded]);

  if (isError) return null;
  if (isLoading) return <LoaderTile mediaHeight={194} mediaAlt="This is a product tile loading" />;

  const productData = data as ProductData;

  const name = productData?.name || productName;
  const description = productData?.description || '';
  const externalWebsiteURL = productData?.externalWebsiteURL;

  const productOwner = !productData?.owner || productData?.owner?.length === 0 ? 'Moata' : productData?.owner;

  return (
    <Card data-testid="product-card">
      <CardActionArea
        component="a"
        aria-label="product"
        href={externalWebsiteURL}
        target="_blank"
        data-testid="product-card-action-area"
        data-gtm-product-id={productId}
        data-gtm-product-name={name}
      >
        <CardMedia
          data-testid="product-image"
          component="div"
          sx={{
            height: 194,
            backgroundImage: `url(${productData?.imageUrl})`,
            backgroundSize: 'cover',
            display: 'flex',
          }}
        >
          <Stack justifyContent="center" alignItems="center" flexGrow={1}>
            <Avatar variant="square" sx={{ bgcolor: 'text.primary', width: 80, height: 80 }}>
              <Typography variant="h3">{shortName}</Typography>
            </Avatar>
          </Stack>
        </CardMedia>

        <CardContent title={description}>
          <Stack spacing={2}>
            <Stack direction="column">
              <Typography variant="h5" noWrap>
                {name}
              </Typography>

              <Typography sx={{ color: 'text.secondary' }} noWrap>
                {`${productOwner} product`}
              </Typography>
            </Stack>

            <Typography
              sx={{
                color: 'text.secondary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {description}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>

      {(productData?.linkOneLink || productData?.linkTwoLink) && (
        <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
          {productData?.linkOneLink && (
            <ActionButton
              label={productData?.linkOneLabel}
              type={productData?.linkOneType}
              link={productData?.linkOneLink}
            />
          )}
          {productData?.linkTwoLink && (
            <ActionButton
              label={productData?.linkTwoLabel}
              type={productData?.linkTwoType}
              link={productData?.linkTwoLink}
            />
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default ProductTile;
