export const CallbackRedirectIdTokenParam = {
  // when we click on a project tile,
  // the param name for the token is configured inside the `projectSource` url template by the creator.
  //
  // looked through many exiting projects, `id_token` is wildly used.
  // so, we use a fixed `id_token` as the param name to simplify the implementation.
  //
  // besides, ideally, we should put it after the `mm_` prefix as well, like `mm_id_token`.
  //
  // note:
  //   the existing logic of the `IdentityCallbackHandler` from `react-moata-identity-integration`
  //   is NOT using this param.
  //   it gets new tokens with the `mm_refresh_token` and `mm_policy` params.
  IdToken: 'id_token',
} as const;

export const CallbackRedirectAuthParam = {
  LoginUrl: 'mm_login',
  LogoutUrl: 'mm_logout',
  Policy: 'mm_policy',
  RefreshToken: 'mm_refresh_token',
} as const;

export const CallbackRedirectStateParam = {
  State: 'mm_state',
  ProjectId: 'mm_project_id',
} as const;
