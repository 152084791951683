import { SerializedError } from '@reduxjs/toolkit';
import { ProjectUserSimpleDTO } from '@h2know-how/identity-sdk';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { AdminItemType } from 'src/features/admin/types';
import { ProductGroupAPI } from 'src/services/api/ProductGroupAPI';

export const useGetGroupUsers = (
  itemType: AdminItemType,
  itemId: number,
  groupId: string
): { users?: ProjectUserSimpleDTO[]; isLoading: boolean; error?: SerializedError } => {
  const {
    data: projectGroupUsers,
    isLoading: isLoadingProjectGroupUsers,
    error: projectGroupUsersError,
  } = ProjectUserAPI.useGetByGroupId(itemId, groupId, { enabled: itemType === AdminItemType.Project });
  const {
    data: productGroupUsers,
    isLoading: isLoadingProductGroupUsers,
    error: productGroupUsersError,
  } = ProductGroupAPI.useGetUsers(itemId, groupId, { enabled: itemType === AdminItemType.Product });

  return {
    users: itemType === AdminItemType.Project ? projectGroupUsers : productGroupUsers,
    isLoading: isLoadingProjectGroupUsers || isLoadingProductGroupUsers,
    error: projectGroupUsersError || productGroupUsersError || undefined,
  };
};
