import { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Routes from './Routes';

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Suspense fallback={null}>
        <Routes />
      </Suspense>
    ),
  },
]);
