import parse from 'html-react-parser';
import jp from 'jsonpath';

import htmlParseOptions from '../htmlParseOptions';
import type { ContentValue } from '../types';

export type Paths = { [key: string]: { path: string; optional?: boolean } };

/**
 * Looks for the given paths in the success object
 * If a non-optional path is not found, throws an error
 */
const parseData = (data: unknown, pathsOnReturnedObj: Paths): unknown => {
  if (Array.isArray(data)) return data.map((obj) => parseData(obj, pathsOnReturnedObj));

  const parsedData: { [key: string]: ContentValue } = {};

  Object.entries(pathsOnReturnedObj).forEach(([key, value]) => {
    const returnedValue = jp.value(data, value.path);

    if (returnedValue === undefined) {
      // if optional, continue to the next one
      if (value.optional) return;

      throw new Error(`value "${key}" at path of return object not found`);
    }

    parsedData[key] = returnedValue ? parse(returnedValue.toString(), htmlParseOptions) : returnedValue.toString();
  });

  return parsedData;
};

export default parseData;
