import { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { UserProfile } from '@h2know-how/identity-sdk';
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import validateName from 'src/utils/validateName';
import useAuth from 'src/hooks/useAuth';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { AppFullRoutePath, AppPage } from 'src/router';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { UserAPI } from 'src/services/api/UserAPI';

export const extractName = ({
  displayName,
  name,
  position,
}: {
  displayName: string;
  name?: string;
  position: number;
}): string => name ?? (displayName.includes(',') ? displayName.split(',')[position].trim() : '');

export const currentUserToFormValues = (
  currentUser: UserProfile
): { firstName: string; lastName: string; mobilePhone: string } => ({
  firstName: extractName({
    displayName: currentUser.displayName,
    name: currentUser.givenName,
    position: 1,
  }),
  lastName: extractName({
    displayName: currentUser.displayName,
    name: currentUser.surname,
    position: 0,
  }),
  mobilePhone: currentUser.mobilePhone ?? '',
});

const UserProfileUpdate: FC = () => {
  useDocumentTitle('Update User Profile ');

  const { isXs } = useGetScreenSize('only');
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(AppFullRoutePath[AppPage.Login]);
    }
  }, [navigate, isAuthenticated]);

  const { data: user } = UserAPI.useFetchMe();
  useEffect(() => {
    if (user?.isFirstTimeUser === false) {
      navigate(AppFullRoutePath[AppPage.Home], { replace: true });
    }
  }, [navigate, isAuthenticated, user]);

  const initialValues = useMemo(() => user && currentUserToFormValues(user), [user]);
  const [firstName, setFirstName] = useState(initialValues?.firstName ?? '');
  const [lastName, setLastName] = useState(initialValues?.lastName ?? '');
  const [mobilePhone, setMobilePhone] = useState(initialValues?.mobilePhone ?? '');
  const isMobilePhoneNumberPossible = isPossiblePhoneNumber(mobilePhone);
  const hasMobilePhoneError = mobilePhone.trim().length > 0 && !isMobilePhoneNumberPossible;
  const { isLoading, mutate: updateUser } = UserAPI.useUpdateMe();

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateUser({ firstName, lastName, mobilePhone });
  };

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{
        p: isXs ? 2 : 4,
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Typography gutterBottom variant="body1">
        Before you get started we just need to get some details to update your account
      </Typography>
      <TextField
        label="First name"
        variant="outlined"
        type="text"
        name="firstName"
        value={firstName}
        disabled={isLoading}
        required
        margin="normal"
        fullWidth
        onKeyDown={(event) => (validateName(event.key) ? event : event.preventDefault())}
        onChange={(event) => setFirstName(event.target.value)}
      />
      <TextField
        label="Last name"
        variant="outlined"
        type="text"
        name="lastName"
        value={lastName}
        disabled={isLoading}
        required
        margin="normal"
        fullWidth
        onKeyDown={(event) => (validateName(event.key) ? event : event.preventDefault())}
        onChange={(event) => setLastName(event.target.value)}
      />
      <TextField
        label="Phone Number"
        variant="outlined"
        name="mobilePhone"
        type="tel"
        disabled={isLoading}
        error={hasMobilePhoneError}
        value={mobilePhone}
        margin="normal"
        fullWidth
        onChange={(event) => setMobilePhone(event.target.value)}
        helperText={hasMobilePhoneError && 'Please enter a full international phone number starting with +'}
      />
      <Stack direction={isXs ? 'column' : 'row'} justifyContent="flex-end" sx={{ pt: isXs ? 0 : 2 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={!firstName.trim() || !lastName.trim() || hasMobilePhoneError}
        >
          Update profile
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default UserProfileUpdate;
