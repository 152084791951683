import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import type { CreateProjectDto, ProjectDto } from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { UserAPI } from 'src/services/api/UserAPI';

const useCreateProject = (): UseMutationResult<ProjectDto, SerializedError, CreateProjectDto> => {
  const { getApiToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateProjectDto) => {
      const token = await getApiToken();
      return authorisedIdentityApiClient(token).Project.create(data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: UserAPI.queryKeys.projects,
      });
    },
  });
};

export default useCreateProject;
