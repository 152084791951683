import { CMS_PATH } from '../configs';
import { getAppOrigin } from './url';

export const fileList = {
  dev: {
    products: [
      '1.json',
      '4.json',
      '5.json',
      '10.json',
      '41.json',
      '71.json',
      '73.json',
      '82.json',
      '83.json',
      '84.json',
      '85.json',
      '126.json',
      '127.json',
      '128.json',
      '129.json',
      '130.json',
      '131.json',
      '132.json',
      '133.json',
      '134.json',
      '135.json',
      '136.json',
      '140.json',
      '141.json',
      '142.json',
      '143.json',
      '144.json',
      '145.json',
      '146.json',
      '147.json',
      '148.json',
      '149.json',
      '150.json',
      '151.json',
      '153.json',
    ],
  },
  qa: {
    products: [
      '94.json',
      '6.json',
      '387.json',
      '543.json',
      '115.json',
      '116.json',
      '117.json',
      '118.json',
      '119.json',
      '121.json',
      '379.json',
      '386.json',
      '3.json',
      '110.json',
      '97.json',
      '99.json',
      '100.json',
      '112.json',
      '98.json',
      '7.json',
      '101.json',
      '8.json',
      '4.json',
      '92.json',
      '95.json',
      '106.json',
      '107.json',
    ],
  },
  prod: {
    products: [
      '1.json',
      '5.json',
      '10.json',
      '11.json',
      '12.json',
      '13.json',
      '14.json',
      '15.json',
      '16.json',
      '17.json',
      '18.json',
      '22.json',
      '25.json',
    ],
  },
};

export const isLocalCMSProductExist = (productId: number): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return fileList[`${CMS_PATH}`].products.includes(`${productId}.json`);
};
export const getLocalCmsUrl = (path: string): string => `${getAppOrigin()}/cms/${CMS_PATH}${path}`;
