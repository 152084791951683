import { ProjectInviteTemplate, ProjectMetadata } from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { PROJECT_BASE_QUERY_KEY } from 'src/services/api/ProjectAPI/constants';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';

export const ProjectAPI = {
  queryKeys: {
    base: PROJECT_BASE_QUERY_KEY,
    list: () => [ProjectAPI.queryKeys.base, QUERY_KEY_VARIANTS.LIST] as QueryKey,
    detail: (projectId: number) => [ProjectAPI.queryKeys.base, QUERY_KEY_VARIANTS.DETAIL, projectId] as QueryKey,
    inviteTemplate: (projectId: number) => [...ProjectAPI.queryKeys.list(), 'invite-template', projectId] as QueryKey,
  },

  useGetById: (projectId: number, options?: UseQueryOptions<ProjectMetadata, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectAPI.queryKeys.detail(projectId),
      async ({ signal }) => authorisedIdentityApiClient(await getApiToken()).Project.getById({ projectId }, { signal }),
      options
    );
  },
  useGetInviteTemplate: (projectId: number, options?: UseQueryOptions<ProjectInviteTemplate, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProjectAPI.queryKeys.inviteTemplate(projectId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).Project.getEmailInviteTemplate({ projectId }, { signal }),
      options
    );
  },
};
