import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from './types';

export const initialState: LoadingState = {
  pageIsLoaded: false,
};

const slice = createSlice({
  name: 'identity/loading',
  initialState,
  reducers: {
    setPageIsLoaded: (state, { payload }: { payload: boolean }) => {
      state.pageIsLoaded = payload;
    },
  },
});

export const { setPageIsLoaded } = slice.actions;

export const { reducer } = slice;
