/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

// Arbitrary number of rows which populate a table
const DEFAULT_ROWS = 15;

const DEFAULT_COLUMNS = 2;

interface TableLoadingSkeletonProps {
  columns?: number;
  rows?: number;
  firstColumnColSpan?: number;
}

export const TableLoadingSkeleton: FC<TableLoadingSkeletonProps> = ({
  columns = DEFAULT_COLUMNS,
  rows = DEFAULT_ROWS,
  firstColumnColSpan,
}) => (
  <>
    {[...Array(rows)].map((_, i) => (
      <TableRow data-testid={`table-loading-skeleton-row-${i}`} key={String(i)}>
        {[...Array(columns)].map((__, j) => (
          <TableCell key={j} colSpan={j === 0 ? firstColumnColSpan : undefined}>
            <Skeleton variant="rectangular" />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);
