import { createUrlObject } from './createUrlObject';

const ALLOWED_MOATA_ROOT_DOMAINS = ['localhost', 'moata.com', 'moata.io'];

export const isAllowedMoataUrl = (url: string): boolean => {
  const urlObj = createUrlObject(url);
  return (
    !!urlObj &&
    ALLOWED_MOATA_ROOT_DOMAINS.some((domain) => urlObj.hostname === domain || urlObj.hostname.endsWith(`.${domain}`))
  );
};
