import { FC } from 'react';
import { Box } from '@mui/material';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';

const Workspace: FC = () => {
  useDocumentTitle('Resources');

  return <Box p={3}>This is the Workspace</Box>;
};

export default Workspace;
