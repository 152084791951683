import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

/**
 * Basic HeaderCell that can be used while waiting for updates to the MUI theme.
 */
export const HeaderCell = styled(TableCell)(({ theme }) => ({
  textTransform: 'uppercase',
  backgroundColor: theme.palette.text.secondary,
  color: theme.palette.primary.contrastText,
}));
