import { FC } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';

import { FetchErrorResult } from 'src/ui/Result';
import Loader from 'src/ui/Loader';
import { ContentValue } from 'src/services/api/useContentQuery';
import { UserAPI } from 'src/services/api/UserAPI';
import TabsContent from './TabsContent';
import useProjectSummary from './hooks/useProjectSummary';

export interface ProjectSummaryType {
  story: ContentValue;
}

interface Props {
  projectId: number;
}

const ProjectsPanelContent: FC<Props> = ({ projectId }: Props) => {
  const { data: projectMetadata, isError: isProjectMetadataError } = UserAPI.useGetProjectById(projectId);
  const projectSummary = useProjectSummary(projectMetadata);

  if (isProjectMetadataError) {
    return <FetchErrorResult />;
  }

  if (projectMetadata === undefined || projectSummary === undefined) {
    return (
      <Stack spacing={6} paddingY={6}>
        <Loader data-testid="detail-panel-loader" />
      </Stack>
    );
  }

  const { name, productShortName, admins, isGroupAdmin } = projectMetadata;

  return (
    <>
      <Stack p={2} direction="row" alignItems="center" justifyContent="flex-start">
        {productShortName && (
          <Avatar variant="square" sx={{ bgcolor: 'text.primary', marginRight: 1 }} aria-label={name}>
            <Typography variant="h6">{productShortName}</Typography>
          </Avatar>
        )}

        <Typography variant="subtitle1" noWrap>
          {name}
        </Typography>
      </Stack>

      <TabsContent
        projectSummary={projectSummary}
        admins={admins}
        projectId={projectId}
        isCurrentUserAdmin={isGroupAdmin}
      />
    </>
  );
};

export default ProjectsPanelContent;
