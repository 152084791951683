import { FC, PropsWithChildren, ReactNode } from 'react';
import { Alert, Warning, Info, TickCircleOutline } from '@mott-macdonald/smi-react-ui-kit/icons';
import { Box, Paper, Typography } from '@mui/material';

interface ResultProps {
  title: ReactNode;
  subtitle?: ReactNode;
  /**
   * The severity of the icon.
   *
   * Same to the severity of the Alert component.
   *
   * @default 'info'
   */
  severity?: 'success' | 'info' | 'warning' | 'error';
  /**
   * A custom icon to be used instead of the default icon for the severity.
   */
  icon?: ReactNode;
  extra?: ReactNode;
  className?: string;
}

const IconMappings: Record<NonNullable<ResultProps['severity']>, ReactNode> = {
  error: <Alert sx={(theme) => ({ fontSize: '4.5rem', color: theme.palette.error.main })} />,
  warning: <Warning sx={(theme) => ({ fontSize: '4.5rem', color: theme.palette.warning.main })} />,
  info: <Info sx={(theme) => ({ fontSize: '4.5rem', color: theme.palette.info.main })} />,
  success: <TickCircleOutline sx={(theme) => ({ fontSize: '4.5rem', color: theme.palette.success.main })} />,
};

export const Result: FC<PropsWithChildren<ResultProps>> = ({
  severity = 'info',
  icon,
  title,
  subtitle,
  extra,
  children,
  className,
}) => {
  return (
    <Box className={className} sx={{ width: '100%', py: 6, px: 4 }}>
      <Box sx={{ textAlign: 'center', lineHeight: 0, mb: 1 }}>{icon ?? IconMappings[severity]}</Box>
      <Typography component="div" variant="h4" sx={{ textAlign: 'center', my: 1 }}>
        {title}
      </Typography>
      {subtitle ? (
        <Typography
          component="div"
          variant="body1"
          sx={(theme) => ({ textAlign: 'center', color: theme.palette.text.secondary })}
        >
          {subtitle}
        </Typography>
      ) : null}
      {extra ? <Box sx={{ textAlign: 'center', mt: 3 }}>{extra}</Box> : null}
      {children ? (
        <Paper elevation={0} sx={{ mt: 3, px: 5, py: 3 }}>
          {children}
        </Paper>
      ) : null}
    </Box>
  );
};
