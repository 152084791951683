const closeDialogFunctions: Set<VoidFunction> = new Set();

export function addCloseFunction(close: () => void): void {
  closeDialogFunctions.add(close);
}

export function removeCloseFunction(close: () => void): void {
  closeDialogFunctions.delete(close);
}

export function closeAllDialogs(): void {
  closeDialogFunctions.forEach((close) => close?.());
  closeDialogFunctions.clear();
}
