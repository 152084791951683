import { Tabs, Tab } from '@mui/material';
import { FC, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { GTMEvents, trackGTMEvent } from 'src/services/telemetry/gtm';
import pages from '../headerLinksList';

const SmHeaderLinks: FC = () => {
  const { pathname } = useLocation();

  const tabIndex = useMemo(() => pages.find((page) => pathname === page.linkUrl)?.index ?? false, [pathname]);

  return (
    <Tabs data-testid="header-tabs" textColor="primary" indicatorColor="primary" value={tabIndex} aria-label="tabs">
      {pages.map((page) => (
        <Tab
          label={page.linkName}
          id={`tabs-${page.index}`}
          aria-controls={`tabs-${page.index}`}
          component={RouterLink}
          to={page.linkUrl}
          key={page.index}
          data-gtm-header-link={page.linkName}
          onClick={() => {
            trackGTMEvent(GTMEvents.header.navItemClick({ target: page.navItemTarget }));
          }}
        />
      ))}
    </Tabs>
  );
};

export default SmHeaderLinks;
