import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import uniqueid from 'lodash.uniqueid';

import LoaderTile from 'src/ui/LoaderTile';
import useGetGridColumns from 'src/hooks/useGetGridColumns';
import ControlledGrid from 'src/ui/ControlledGrid';
import { FetchErrorResult } from 'src/ui/Result';
import { ProductAPI } from 'src/services/api/ProductAPI';
import { isLocalCMSProductExist } from 'src/utils/localCMS';
import ProductTile from '../ProductTile';

interface ProductsListProps {
  onLoaded: () => void;
}

const ProductsList: FC<ProductsListProps> = ({ onLoaded }) => {
  const { isError, data } = ProductAPI.useFetchProducts({ Visible: true });
  const filteredData = data?.filter((item) => item.isDefault !== true && isLocalCMSProductExist(item.productId));
  const [productDataLoaded, setProductDataLoaded] = useState<number[]>([]);

  useEffect(() => {
    if (filteredData?.every((product) => productDataLoaded.includes(product.productId))) {
      onLoaded();
    }
  }, [filteredData, productDataLoaded, onLoaded]);

  const columns = useGetGridColumns();

  if (isError) return <FetchErrorResult />;

  return (
    <>
      <Typography variant="h6" p={3} paddingBottom={0}>
        All Products
      </Typography>

      <ControlledGrid columns={columns}>
        {!filteredData
          ? [...Array(columns)].map(() => (
              <LoaderTile key={uniqueid('key_')} mediaAlt="This is a product tile loading" mediaHeight={194} />
            ))
          : filteredData.map((product) => (
              <ProductTile
                key={product.productId}
                productId={product.productId}
                productContentUrl={product.productContentUrl}
                shortName={product.shortName}
                productName={product.name}
                onLoaded={() =>
                  setProductDataLoaded((previousProductDataLoaded) => {
                    if (previousProductDataLoaded.includes(product.productId)) {
                      return previousProductDataLoaded;
                    }
                    return [...previousProductDataLoaded, product.productId];
                  })
                }
              />
            ))}
      </ControlledGrid>
    </>
  );
};

export default ProductsList;
