import { Store } from 'redux';
import { KEY_PREFIX, PersistConfig, getStoredState, REHYDRATE } from 'redux-persist';

const persistCrosstab = <State>(store: Store, persistConfig: PersistConfig<State>): void => {
  window.addEventListener(
    'storage',
    async (e: StorageEvent) => {
      if (e.key && e.key.indexOf(KEY_PREFIX) === 0 && e.key.indexOf(persistConfig.key) > 0) {
        const state = await getStoredState(persistConfig);
        store.dispatch({
          type: REHYDRATE,
          key: persistConfig.key,
          payload: state,
        });
      }
    },
    false
  );
};

export default persistCrosstab;
