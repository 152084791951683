import {
  ProductGroupCreateDTO,
  ProductGroupDTO,
  ProductGroupUpdateDTO,
  ProjectUserSimpleDTO,
} from '@h2know-how/identity-sdk';
import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { PRODUCT_GROUP_BASE_QUERY_KEY } from 'src/services/api/ProductGroupAPI/constants';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';

export const ProductGroupAPI = {
  queryKeys: {
    base: PRODUCT_GROUP_BASE_QUERY_KEY,
    byProductId: (productId: number) => [ProductGroupAPI.queryKeys.base, productId] as QueryKey,
    list: (productId: number) =>
      [...ProductGroupAPI.queryKeys.byProductId(productId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
    detail: (productId: number, groupId: string) =>
      [...ProductGroupAPI.queryKeys.byProductId(productId), QUERY_KEY_VARIANTS.DETAIL, groupId] as QueryKey,
    detailUsers: (productId: number, groupId: string) =>
      [...ProductGroupAPI.queryKeys.detail(productId, groupId), 'users'] as QueryKey,
  },
  useCreate: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ productId, payload }: { productId: number; payload: ProductGroupCreateDTO }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.create(productId, payload),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProductGroupAPI.queryKeys.byProductId(productId));
      },
    });
  },
  useUpdate: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({
        groupId,
        productId,
        payload,
      }: {
        productId: number;
        groupId: string;
        payload: ProductGroupUpdateDTO;
      }) => authorisedIdentityApiClient(await getApiToken()).ProductGroup.update(productId, groupId, payload),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProductGroupAPI.queryKeys.byProductId(productId));
      },
    });
  },
  useDelete: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ groupId, productId }: { groupId: string; productId: number }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.deleteOne(productId, groupId),
      onSuccess: async (_, { productId }) => {
        await queryClient.invalidateQueries(ProductGroupAPI.queryKeys.byProductId(productId));
      },
    });
  },
  useGetById: (productId: number, groupId: string, options?: UseQueryOptions<ProductGroupDTO, SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProductGroupAPI.queryKeys.detail(productId, groupId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.getById(productId, groupId, { signal }),
      options
    );
  },
  useGetByProductId: (productId: number, options?: UseQueryOptions<ProductGroupDTO[], SerializedError>) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProductGroupAPI.queryKeys.list(productId),
      async ({ signal }) => authorisedIdentityApiClient(await getApiToken()).ProductGroup.getAll(productId, { signal }),
      options
    );
  },
  useGetUsers: (
    productId: number,
    groupId: string,
    options?: UseQueryOptions<ProjectUserSimpleDTO[], SerializedError>
  ) => {
    const { getApiToken } = useAuth();

    return useQuery(
      ProductGroupAPI.queryKeys.detailUsers(productId, groupId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.getUsers(productId, groupId, { signal }),
      options
    );
  },
  useAddUsers: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ productId, groupId, userIds }: { productId: number; groupId: string; userIds: string[] }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.addUsers(productId, groupId, userIds),
      onSuccess: async (_, { productId, groupId }) => {
        await queryClient.invalidateQueries(ProductGroupAPI.queryKeys.detailUsers(productId, groupId));
      },
    });
  },
  useRemoveUsers: () => {
    const { getApiToken } = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async ({ productId, groupId, userIds }: { productId: number; groupId: string; userIds: string[] }) =>
        authorisedIdentityApiClient(await getApiToken()).ProductGroup.removeUsers(productId, groupId, userIds),
      onSuccess: async (_, { productId, groupId }) => {
        await queryClient.invalidateQueries(ProductGroupAPI.queryKeys.detailUsers(productId, groupId));
      },
    });
  },
};
