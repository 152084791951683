import z from 'zod';

const groupFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(5, 'Name must be at least 5 characters.')
    .max(50, 'Name must be less than 50 characters')
    .regex(/^[\w\s]+$/, 'Name must be alphanumeric.'),
});

export default groupFormSchema;
