import { RefreshTokenResult } from '@h2know-how/identity-sdk';
import { identityApiClient } from 'src/services/api/identityApiClient';

import { CallbackPageHashParam } from 'src/router';
import { AuthState } from '../types';

/**
 * this is the backward auth flow, when the user is redirected back to Identity app from authenticated Moata sub-apps.
 */
const authoriseWithHash = async (
  hash: string,
  authState: AuthState,
  signal: AbortSignal
): Promise<{
  b2cTokens: RefreshTokenResult;
  policy: string;
}> => {
  const { b2cTokens: currentB2cTokens, policy: currentPolicy } = authState;

  let refreshToken = currentB2cTokens?.refresh_token ?? null;
  let policy = currentPolicy ?? null;

  const hashParams = new URLSearchParams(hash.substr(1, hash.length));

  if (hashParams.has(CallbackPageHashParam.RefreshToken) && hashParams.has(CallbackPageHashParam.Policy)) {
    refreshToken = hashParams.get(CallbackPageHashParam.RefreshToken);
    policy = hashParams.get(CallbackPageHashParam.Policy);
  }

  if (!refreshToken || !policy) {
    throw new Error('invalid hash value in callback');
  }

  const b2cTokens = await identityApiClient.OAuth2.refreshToken({ refresh_token: refreshToken, policy }, { signal });

  return {
    b2cTokens,
    policy,
  };
};

export default authoriseWithHash;
