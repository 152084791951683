import { FC, ReactNode } from 'react';
import { clearDetailPanelProps } from 'src/store/detailPanel';

import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAppSelector } from 'src/store/useAppSelector';
import ProjectsPanelContent from './ProjectsPanelContent';
import StdDetailPanel from './StdDetailPanel';
import SmDetailPanel from './SmDetailPanel';

/**
 * A generic detail panel. Opens when the detailPanelProps is set in redux state
 */
const DetailPanel: FC = () => {
  const dispatch = useAppDispatch();

  const detailPanelProps = useAppSelector((state) => state.detailPanel.props);

  const { isMd } = useGetScreenSize('up');

  const handlePanelClose = (): void => {
    dispatch(clearDetailPanelProps());
  };

  const getDetailPanelContent = (): ReactNode => {
    if (detailPanelProps?.resourceType === 'project') return <ProjectsPanelContent projectId={detailPanelProps.id} />;
    return null;
  };

  const getDetailPanelTitle = (): string => {
    if (detailPanelProps?.resourceType === 'project') return 'Project details';
    return '';
  };

  if (!detailPanelProps) return null;

  return isMd ? (
    <StdDetailPanel getDetailPanelContent={getDetailPanelContent} handlePanelClose={handlePanelClose} />
  ) : (
    <SmDetailPanel
      isOpen={!!detailPanelProps}
      handlePanelClose={handlePanelClose}
      getDetailPanelTitle={getDetailPanelTitle}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};

export default DetailPanel;
