import { createAsyncThunk } from '@reduxjs/toolkit';

import { identityApiClient } from 'src/services/api/identityApiClient';
import StateNotReadyForActionError from 'src/store/errors/StateNotReadyForActionError';
import DependencyError from 'src/store/errors/DependencyError';
import { decodeIdToken } from 'src/utils/jwtHelpers';
import { AppFullRoutePath, AppPage } from 'src/router';
import type { AppDispatch } from 'src/store/types';
import { getAppOrigin } from 'src/utils/url';
import serializeApiError from '../../utils/serializeApiError';
import { AuthState, LogoutResponsePayload } from '../types';
import authoriseWithHash from '../utils/authoriseWithHash';
import getPolicyMetadata from '../utils/getPolicyMetadata';
import { AUTH_SLICE_NAME } from '../sliceName';

const logout = createAsyncThunk<
  LogoutResponsePayload,
  { hash: string },
  { dispatch: AppDispatch; state: { auth: AuthState } }
>(
  `${AUTH_SLICE_NAME}/logout`,
  async ({ hash }, { getState, signal }) => {
    let {
      auth: { b2cTokens },
    } = getState();

    // if there is a hash, we process it to refresh the token
    if (hash) {
      b2cTokens = (await authoriseWithHash(hash, getState().auth, signal)).b2cTokens;
    }

    if (b2cTokens === null) {
      throw new StateNotReadyForActionError('You are not logged in');
    }

    const { sub: userId, emails } = decodeIdToken(b2cTokens.id_token);

    await identityApiClient.OAuth2.invalidateToken({ userId });

    const { end_session_endpoint: endSessionEndpoint, idpLogoutUrl = null } = await getPolicyMetadata(
      emails[0],
      signal
    );

    if (endSessionEndpoint === undefined) {
      // No error message so that the default error message is displayed to the user
      throw new DependencyError();
    }

    const logoutSearchParams = new URLSearchParams({
      post_logout_redirect_uri: `${getAppOrigin()}${AppFullRoutePath[AppPage.LogoutConfirmation]}`,
    });

    return {
      logoutUrl: `${endSessionEndpoint}&${logoutSearchParams.toString()}`,
      idpLogoutUrl,
    };
  },
  { serializeError: serializeApiError }
);

export default logout;
