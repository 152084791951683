import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';

interface LoadingSpinnerProps {
  /**
   * - `true` will make the backdrop visible cover the whole container.
   * - `'invisible'` will still make the backdrop cover the whole container but just invisible.
   *   (for the case when you want to show the spinner in an **empty** container.)
   * - `'fullWindow'` will make the spinner cover the whole window.
   */
  cover?: boolean | 'invisible' | 'fullWindow';
  /**
   * When not provided, the default size follows the theme.
   * Aim to have a consistent size across the app.
   *
   * - `'inherit'` will inherit the font size from the parent.
   */
  size?: 'inherit';
  /**
   * the class name of the spinner itself.
   */
  className?: string;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ cover, size, className }) => {
  const spinnerSize = size === 'inherit' ? '1em' : undefined;
  const spinner = <CircularProgress color="inherit" size={spinnerSize} className={className} />;

  if (cover) {
    const isFullWindow = typeof cover === 'string' && cover === 'fullWindow';
    const isInvisible = typeof cover === 'string' && cover === 'invisible';

    return (
      <Backdrop
        open
        invisible={isInvisible}
        sx={{
          // to ignore the top margin that added by MUI when the backdrop is placed inside a Stack
          margin: '0!important',
          position: isFullWindow ? 'fixed' : 'absolute',
          backgroundColor: isInvisible ? undefined : 'action.disabled',
        }}
      >
        {spinner}
      </Backdrop>
    );
  }

  return spinner;
};
