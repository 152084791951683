import { useCallback } from 'react';
import { B2CTokens, RequestError } from '@h2know-how/identity-sdk';
import { getIsIdTokenActive } from 'src/utils/tokenHelpers';
import { useAppSelector } from 'src/store/useAppSelector';
import { getB2cTokens, getPolicy } from 'src/store/auth/selectors';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { identityApiClient } from 'src/services/api/identityApiClient';
import { setTokens } from 'src/store/auth';
import AuthenticationError from 'src/store/errors/AuthenticationError';

export interface UseAuthResult {
  isAuthenticated: boolean;
  refreshTokens: (signal?: AbortSignal) => Promise<B2CTokens>;
  getApiToken: (signal?: AbortSignal) => Promise<string>;
}

const useAuth = (): UseAuthResult => {
  const dispatch = useAppDispatch();
  const policy = useAppSelector(getPolicy) ?? undefined;
  const { id_token: idToken, refresh_token: refreshToken } = useAppSelector(getB2cTokens) ?? {};

  const isAuthenticated = getIsIdTokenActive(idToken);

  const refreshTokens = useCallback(
    async (signal?: AbortSignal) => {
      if (!refreshToken || !policy) {
        throw new AuthenticationError('You are not logged in');
      }

      try {
        const newTokens = await identityApiClient.OAuth2.refreshToken(
          { refresh_token: refreshToken, policy },
          { signal }
        );

        dispatch(setTokens(newTokens));

        return newTokens;
      } catch (e) {
        if (e instanceof RequestError) {
          // a request error indicates that the request was handled by
          // the server but was invalid for some reason.
          // as the request is invalid, we can assume that a token
          // refresh is not possible

          // throwing an AuthenticationError will log the user out
          // in the requestErrorHandlingMiddleware
          throw new AuthenticationError('Your session has expired. Please log in again.');
        }
        throw e;
      }
    },
    [dispatch, policy, refreshToken]
  );

  const getApiToken = useCallback(
    async (signal?: AbortSignal) => {
      if (getIsIdTokenActive(idToken)) return idToken;
      // the token may be refreshable when it's about to expire,
      // or it may be not refreshable when it's already expired.
      // so the request below may fail.
      return (await refreshTokens(signal)).id_token;
    },
    [idToken, refreshTokens]
  );

  return {
    isAuthenticated,
    refreshTokens,
    getApiToken,
  };
};

export default useAuth;
