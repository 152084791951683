import { AsyncThunk, isRejected, SerializedError } from '@reduxjs/toolkit';
import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'src/store/useAppDispatch';

type AbortFn = (reason?: string) => void;

type WrappedAppDispatch<Args> = (args: Args) => Promise<void>;
interface AsyncDispatchControl<Args> {
  dispatch: WrappedAppDispatch<Args>;
  isPending: boolean;
  error: SerializedError | undefined;
  abort?: AbortFn;
}

const useAsyncThunkDispatch = <Returns, Args>(
  asyncThunk: AsyncThunk<Returns, Args, Record<string, unknown>>,
  options?: { abortOnChange?: DependencyList | undefined }
): AsyncDispatchControl<Args> => {
  const appDispatch = useAppDispatch();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<SerializedError | undefined>();
  const abortRef = useRef<AbortFn>();

  const dispatch = useCallback(
    async (args: Args) => {
      // abort any existing calls
      abortRef.current?.();

      setIsPending(true);
      setError(undefined);
      const promise = appDispatch(asyncThunk(args));
      abortRef.current = promise.abort;
      const action = await promise;
      if (isRejected(action)) {
        setError(action.error);
      }
      setIsPending(false);
    },
    [appDispatch, asyncThunk]
  );

  // abort based upon the dependencies list if provided
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => options?.abortOnChange && abortRef.current?.(), options?.abortOnChange ?? []);

  return {
    dispatch,
    isPending,
    error,
    abort: abortRef.current,
  };
};

export default useAsyncThunkDispatch;
