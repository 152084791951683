import Button from '@mui/material/Button';
import { FC } from 'react';
import { useBoolean } from 'react-use';
import { ItemsListManageModal } from './ItemsListManageModal';
import { ItemManagementController, ItemsManagementTextConstants } from './types';

interface ProjectUserManageGroupsButtonProps {
  title: string;
  disabled?: boolean;
  ItemsController: ItemManagementController;
  constants: ItemsManagementTextConstants;
}

export const ItemsListManageModalButton: FC<ProjectUserManageGroupsButtonProps> = ({
  title,
  disabled = false,
  ItemsController,
  constants,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useBoolean(false);

  return (
    <>
      <Button disabled={disabled} fullWidth onClick={toggleIsModalOpen}>
        {title}
      </Button>
      {isModalOpen ? (
        <ItemsListManageModal
          ItemsController={ItemsController}
          constants={constants}
          onClose={() => toggleIsModalOpen(false)}
          open={isModalOpen}
        />
      ) : null}
    </>
  );
};
