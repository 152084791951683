import { ProjectAPI } from 'src/services/api/ProjectAPI/ProjectAPI';
import { useCurrentProjectId } from './useCurrentProjectId';

export const useCurrentProject = (
  options?: Parameters<typeof ProjectAPI.useGetById>[1]
): ReturnType<typeof ProjectAPI.useGetById> => {
  const projectId = useCurrentProjectId();

  return ProjectAPI.useGetById(Number(projectId), { enabled: !!projectId, ...options });
};
