import { Breadcrumbs, Stack } from '@mui/material';
import { FC, useState } from 'react';
import Loader from 'src/ui/Loader';
import { GroupUsersTable } from 'src/features/admin/components/GroupUsersTable';
import { FetchErrorResult } from 'src/ui/Result';
import Search from 'src/ui/Search';
import { useCurrentProject } from 'src/features/admin/hooks/useCurrentProject';
import { useCurrentProjectGroup } from 'src/features/admin/hooks/useCurrentProjectGroup';
import { useCurrentGroupId } from 'src/features/admin/hooks/useCurrentGroupId';
import { useCurrentProjectId } from 'src/features/admin/hooks/useCurrentProjectId';
import { AdminItemType } from 'src/features/admin/types';
import { GroupConfigurationHeader } from 'src/features/admin/components/GroupConfigurationHeader';

export const ProjectGroupConfigurationPage: FC = () => {
  const projectGroupId = useCurrentGroupId();
  const projectId = useCurrentProjectId();

  const { data: project, isLoading: isProjectLoading, isError: isProjectError } = useCurrentProject();
  const { data: group, isLoading: isGroupLoading, isError: isGroupError } = useCurrentProjectGroup();

  const [filterValue, setFilterValue] = useState('');

  if (!projectId || !projectGroupId || isGroupError || isProjectError) {
    return <FetchErrorResult />;
  }

  if (isProjectLoading || isGroupLoading) return <Loader flex={1} />;

  // If there is no error and loading is done, we will have a project and group. So this acts as a type guard.
  if (!project || !group) return null;

  return (
    <>
      <Breadcrumbs />
      <GroupConfigurationHeader
        itemType={AdminItemType.Project}
        group={group}
        itemId={Number(projectId)}
        productId={project?.productId ?? 0}
      />
      <Stack padding={2}>
        <Search
          label="Search Users"
          name="searchUsers"
          filterValue={filterValue}
          onFilterValueChange={setFilterValue}
        />
      </Stack>
      <GroupUsersTable
        itemType={AdminItemType.Project}
        itemId={Number(projectId)}
        groupId={projectGroupId}
        filterValue={filterValue}
      />
    </>
  );
};
