import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { useCurrentProjectUserId } from 'src/features/admin/hooks/useCurrentProjectUserId';
import { useCurrentProjectId } from './useCurrentProjectId';

export const useCurrentProjectUser = (
  options?: Parameters<typeof ProjectUserAPI.useGetById>[2]
): ReturnType<typeof ProjectUserAPI.useGetById> => {
  const projectUserId = useCurrentProjectUserId();
  const projectId = useCurrentProjectId();

  return ProjectUserAPI.useGetById(Number(projectId), String(projectUserId), {
    enabled: !!projectId && !!projectUserId,
    ...options,
  });
};
