import { RequestOptions, BaseClient } from '@h2know-how/base-api-client';

import { ContentOptions, ContentNode } from './types';

class ContentClient extends BaseClient {
  contentOptions?: ContentOptions;

  public constructor(url: string, apiToken: string, contentOptions?: ContentOptions) {
    super({ apiToken, baseUrl: url });
    this.contentOptions = contentOptions;
  }

  public fetchContent = (options?: RequestOptions): Promise<ContentNode | ContentNode[]> =>
    this.get<ContentNode | ContentNode[]>({ pathTemplate: '', query: this.contentOptions }, options);
}

export default ContentClient;
