import { FC } from 'react';
import AuthLayout from 'src/pages/layouts/AuthLayout';
import { AuthLoadingSpinner } from '../AuthLoadingSpinner/AuthLoadingSpinner';

export const AuthLoadingPage: FC = () => {
  return (
    <AuthLayout>
      <AuthLoadingSpinner />
    </AuthLayout>
  );
};
